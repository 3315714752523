import { history } from "src/stores";
import { openWarningNotification } from "../notification";
import { FaGuilded } from "react-icons/fa";
import { MdSportsEsports } from "react-icons/md";

interface iMobileNavMenuItem {
  icon?: string;
  name: string;
  url?: string;
  page?: string;
  isActive?: boolean;
  setBeforePage: (page: string) => void;
  setOpenDrawer: (value: boolean) => void;
  iconProps?: string;
}

export const MobileNavMenuItem = (props: iMobileNavMenuItem) => {
  const {
    icon,
    name,
    url,
    page,
    isActive = false,
    setBeforePage,
    setOpenDrawer,
    iconProps,
  } = props;

  const getIconPropIcon = () => {
    const iconPropsClasses = "w-[24px] h-[24px] mr-space-2 text-grey-600";
    if (iconProps === "FaGuilded")
      return <FaGuilded className={iconPropsClasses} />;
    if (iconProps === "MdSportsEsports")
      return <MdSportsEsports className={iconPropsClasses} />;
    return null;
  };

  const redirect = () => {
    if (url) {
      setBeforePage("");
      setOpenDrawer(false);

      setTimeout(() => {
        window.open(url, "_self");
      }, 300);
    }

    if (page) {
      setBeforePage(page);
      setOpenDrawer(false);

      setTimeout(() => {
        history.push(page);
      }, 300);
    }

    return openWarningNotification("Coming Soon!");
  };

  return (
    <div
      className="relative rounded-s cursor-pointer"
      style={{
        background: !isActive
          ? "#2E2E2E"
          : "linear-gradient(90deg, rgba(0, 0, 0, 0) 16px, #2E2E2E 16px, #2E2E2E 100%)",
      }}
      onClick={() => redirect()}>
      <div
        className={`${
          isActive
            ? "bg-[url('/assets/v4/mobile-nav-menu-item-border-active.png')]"
            : ""
        } bg-no-repeat flex flex-row items-center gap-space-1 px-space-4 py-space-2`}
        style={{
          backgroundSize: "auto 100%",
        }}>
        {iconProps && getIconPropIcon()}
        {icon && <img src={icon} alt={name} className="w-[24px] h-[24px]" />}
        <p className="text-l text-grey-400 leading-[19px]">{name}</p>
      </div>
      {isActive && (
        <img
          src="/assets/v4/mobile-nav-menu-point.png"
          alt="nav-point"
          className="absolute top-[50%] left-[-32px] transform translate-y-[-50%] w-[34px] h-[34px]"
        />
      )}
    </div>
  );
};
