import { useState } from "react";
import Slider from "react-slick";
import { bannerContent } from "src/config/landing";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { iBannerContent } from "src/types/landing";
import { history } from "src/stores";
import { openWarningNotification } from "./notification";
import { t } from "i18next";

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
};

export const LandingBannerCaseOpen = () => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState<number>(1);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleClickBanner = (item: iBannerContent) => {
    if (isDragging) return;
    if (item?.id === 1) {
      return history.push("/mystery-box/66fd32ef2409e14f9def244f");
    }
    if (item?.id === 2) {
      return history.push(`/mystery-box/66fe8a852409e14f9def24c4`);
    }
    if (item?.id === 3) {
      return openWarningNotification(t("message.coming-soon"));
    }
    if (item?.id === 4) {
      return window.open("https://discord.gg/invite/gedaesports", "_blank");
    }
  };

  return (
    <div>
      <Slider
        {...settings}
        afterChange={(index) => setCurrentBannerIndex(bannerContent[index]?.id)}
      >
        {bannerContent.map((item) => (
          // if mouse is not dragging, then allow click event
          <div
            key={item.id}
            className="cursor-pointer"
            onMouseDown={(e) => {
              if (e.button === 0) setIsDragging(false);
            }}
            onMouseMove={(e) => {
              if (e.button === 0) setIsDragging(true);
            }}
            onMouseUp={(e) => {
              if (e.button === 0) {
                !isDragging && handleClickBanner(item);
              }
            }}
          >
            <div className="relative flex rounded-lg">
              <img
                src={item.image}
                alt={item.altText}
                className="rounded-lg border-none w-full"
              />
              <div className="absolute flex flex-col pt-space-2 pl-space-4 w-[55%]">
                <h1 className="text-grey-100 blender-medium font-[900] text-[20px]">
                  {item.title}
                </h1>
                <p className="text-grey-400 blender-medium font-[400] text-m leading-[16px]">
                  {item.descriptions}
                </p>
              </div>

              <div className="absolute bottom-2 flex flex-row p-space-4">
                {[...Array(4)].map((_, i) => (
                  <div
                    key={i}
                    className={`w-[8px] h-[8px] rounded-full mr-space-2 ${
                      currentBannerIndex === i + 1
                        ? "bg-orange-800"
                        : "bg-grey-800"
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
