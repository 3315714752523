import { getCurrencyIcon } from "src/utils/image";
import { Token } from "src/types/currency";

export const CURRENCIES = [
  {
    icon: getCurrencyIcon("GSTAR"),
    value: "usdt",
    name: "GSTAR",
    initial: "GS",
    hasDepositButton: true,
    isHidden: false,
    token: Token.STAR,
  },
  {
    icon: getCurrencyIcon("MAX"),
    value: "max",
    name: "GMAX",
    initial: "GMAX",
    hasDepositButton: true,
    isHidden: false,
    token: Token.MAX,
  },
  {
    icon: getCurrencyIcon("GDD"),
    value: "gdd",
    name: "GDD",
    initial: "GDD",
    hasDepositButton: false,
    isHidden: true,
    token: Token.GDD,
  },
  {
    icon: getCurrencyIcon("GP"),
    value: "gp",
    name: "GP",
    initial: "GP",
    hasDepositButton: false,
    isHidden: true,
    token: Token.GP,
  },
];
