import { openWarningNotification } from "src/components";
import { formatDollar } from "src/utils";

interface iDashboardReferral {}

export const DashboardReferral = (props: iDashboardReferral) => {
  const btnStyle = `w-full border-[1px] border-solid p-space-2 rounded-xs cursor-pointer mx-auto flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px]`;
  const v2PrimaryHoverStyle = `hover:drop-shadow-[0_4px_4px_rgba(254, 82, 25,0.5)] transition drop-shadow duration-700`;
  return (
    <div className="bg-[#00000066] p-space-2 sm:p-space-4 rounded-s backdrop-blur-[8px]">
      <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[500] blender-medium mb-space-6">
        Referral
      </p>
      <div className="flex flex-col items-center gap-space-4">
        <img
          src="/assets/v4/dashboard-referral-icon.png"
          alt="referral"
          className="w-[100px]"
        />
        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100 font-[500] blender-medium">
          Invite friends and Earn more GSTAR
        </p>
        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
          You'll earn 10% of your friends' points as a bonus. They will receive
          # free points after using your referral link.
        </p>
        <div>
          <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100 text-center font-[500] blender-medium">
            You've earned
          </p>
          <div className="flex flex-row items-center justify-center gap-space-0">
            <img
              src="/assets/v4/geda-star.png"
              alt="geda-star"
              className="w-[20px] h-[20px]"
            />
            <p className="inline small-content text-grey-100 text-center font-[500] blender-medium">
              {formatDollar(10)}
              {/* TODO: API integration required */}
            </p>
          </div>
        </div>
        <div
          className={`${btnStyle} ${v2PrimaryHoverStyle} sm:max-w-[423px] bg-[#FE521933] border-orange-600`}
          onClick={() => openWarningNotification("Coming Soon!")}
        >
          <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-orange-600">
            Start Referring
          </p>
        </div>
      </div>
    </div>
  );
};
