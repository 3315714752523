import { useContext } from "react";
import clsx from "clsx";
import { CaseOpeningRouletteContext } from "./case-opening-roulette-provider";
import { CaseOpeningRoulette } from "./case-opening-roulette";
import { IMysteryBox, IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { screenSize } from "src/types/rwd";

interface ICaseOpeningRouletteLayout {
  totalRoulette: number;
  prizes: IMysteryBoxPrizeDetail[];
  boxData: IMysteryBox | null;
}

const rouletteStyleMapByNum: { [key: number]: string } = {
  1: "w-full py-space-4 xs:w-full sm:w-full md:w-full md:py-4 l:w-full",
  2: "w-full py-space-4 xs:w-full sm:w-full md:w-full md:py-4 l:w-full",
  3: "w-full py-space-4 xs:w-full sm:w-[50%] md:w-[33.33%] md:py-4 l:w-[33.33%]",
  4: "w-full py-space-2 xs:w-[50%] sm:w-[50%] md:w-[50%] md:py-4 l:w-[50%]",
  5: "w-1/2 py-space-4 xs:w-[50%] sm:w-[33.33%] md:w-[33.33%] md:py-4 l:w-[33.33%]",
  10: "w-1/2 py-space-0 xs:w-1/2 sm:w-1/3 md:w-1/5 md:py-4 l:w-1/5",
};

const boxItemStyleMapByNum: { [key: number]: string } = {
  1: "h-[200px]",
  2: "h-[200px]",
  3: "h-[148px] sm:h-[200px]",
  4: "sm:h-[148px] md:h-[200px]",
  5: "h-[148px] sm:h-[200px]",
  10: "sm:h-[148px] md:h-[200px]",
};

const screenSizeToBoxWidthMap: { [key in screenSize]: number } = {
  [screenSize.DEFAULT]: 148,
  [screenSize.XS]: 148,
  [screenSize.SM]: 148,
  [screenSize.MD]: 200,
  [screenSize.L]: 200,
  [screenSize["2XL"]]: 200,
};

export const CaseOpeningRouletteLayout = ({
  totalRoulette,
  prizes,
  boxData,
}: ICaseOpeningRouletteLayout) => {
  const { screenSize } = useContext(CaseOpeningRouletteContext);
  const boxWidth = screenSizeToBoxWidthMap[screenSize];

  return (
    <div className="flex justify-center flex-wrap w-full h-full content-center overflow-hidden">
      {prizes.map((prize, index) => (
        <div
          key={prize.name + index}
          className={clsx(
            "relative max-w-fit px-space-0",
            rouletteStyleMapByNum[totalRoulette]
          )}
        >
          <CaseOpeningRoulette
            prize={prize}
            boxWidth={boxWidth}
            boxItemClassName={clsx(boxItemStyleMapByNum[totalRoulette], {
              "[&_.case-opening-mystery-box-item-img]:w-[60%] sm:[&>div>.case-opening-mystery-box-item-img]:w-full":
                totalRoulette > 2,
            })}
            currency={boxData?.currency}
          />
        </div>
      ))}
    </div>
  );
};
