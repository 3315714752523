import { useState } from "react";
import { MarketplaceSkin } from "./marketplace-skin";
import { useWindowSize } from "@uidotdev/usehooks";
import { t } from "i18next";

interface iLandingMarketplace {}

export const LandingMarketplace = (props: iLandingMarketplace) => {
  const [skins] = useState<string[]>([]);
  const [selectedSkins, setSelectedSkins] = useState<string[]>([]);

  const size: any = useWindowSize();

  return (
    <div
      className="relative h-full flex items-start items-stretch flex-col mx-0 md:mx-space-2 mb-space-4 p-space-2 md:p-space-6 rounded-s"
      style={{
        background: "linear-gradient(360deg, #111111 0%, #262626 100%)",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%)",
        boxShadow: "0px 4px 4px 0px #00000040 inset",
      }}
    >
      <p className="text-l md:text-xxml font-[500] leading-[19px] md:leading-[28px] blender-medium mb-space-2 md:mb-space-4">
        {t("landing.marketplace")}
      </p>
      <div className="flex-[1] min-h-[100px]">
        <div
          className={`grid ${
            size?.width < 600 ? "grid-cols-2" : "grid-cols-3"
          } md:grid-cols-2 2xl:grid-cols-3 gap-space-2 mb-space-2`}
        >
          {skins &&
            skins.length > 0 &&
            skins.map((item: any, i: number) => {
              if (size?.width < 600) {
                if (i > 1) {
                  return null;
                }
              } else if (size?.width < 1080) {
                if (i > 2) {
                  return null;
                }
              } else if (size?.width >= 1080 && size?.width <= 1400) {
                if (i > 1) {
                  return null;
                }
              } else if (size?.width >= 1920) {
                if (i > 2) {
                  return null;
                }
              } else {
                if (i > 1) {
                  return null;
                }
              }

              return (
                <MarketplaceSkin
                  key={i}
                  skinId={item}
                  selectedSkins={selectedSkins}
                  setSelectedSkins={setSelectedSkins}
                />
              );
            })}
        </div>
      </div>
      <button className="w-full blender-medium leading-[19px] py-[14px] cursor-not-allowed btn-primary">
        BUY NOW
      </button>

      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%)",
        }}
      >
        <p className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] text-[24px] text-grey-100 leading-[24px] acpex whitespace-nowrap uppercase">
          {t("general.coming-soon")}
        </p>
      </div>
    </div>
  );
};
