import { ILanguageList } from "../types/nav";

export const LANGUAGE_LIST: ILanguageList[] = [
  {
    code: "en",
    name: "English",
    nativeName: "English",
    abbreviation: "ENG",
    enabled: true,
  },
  {
    code: "tc",
    name: "(Traditional) Chinese",
    nativeName: "繁體中文",
    abbreviation: "(繁體中文) CH",
    enabled: true,
  },
  {
    code: "ko",
    name: "Korean",
    nativeName: "한국어",
    abbreviation: "KR",
    enabled: false,
  },
  {
    code: "ja",
    name: "Japanese",
    nativeName: "日本語",
    abbreviation: "JP",
    enabled: false,
  },
  {
    code: "id",
    name: "Bahasa",
    nativeName: "Bahasa",
    abbreviation: "ID",
    enabled: false,
  },
  {
    code: "tl",
    name: "Tagalog",
    nativeName: "Tagalog",
    abbreviation: "TL",
    enabled: false,
  },
  {
    code: "fr",
    name: "French",
    nativeName: "Français",
    abbreviation: "FR",
    enabled: false,
  },
  {
    code: "de",
    name: "German",
    nativeName: "Deutsch",
    abbreviation: "DE",
    enabled: false,
  },
];
