import { FC, useContext } from "react";
import clsx from "clsx";
import { IMessage } from "src/types/inbox";
import { InboxContext } from "./inbox-provider";
import { t } from "i18next";

interface IActionMessageProps {
  className?: String;
  message: IMessage;
}

const ActionMessage: FC<IActionMessageProps> = ({ className, message }) => {
  const { setModal } = useContext(InboxContext);
  const { imageUrl, subject, summary } = message;

  return (
    <div className={clsx(className, "rounded-xs bg-black-100")}>
      <div className="grid h-[84px] overflow-hidden self-stretch rounded-xs">
        <img
          className="h-[84px] w-full place-self-center object-cover"
          src={imageUrl}
          alt=""
        />
      </div>
      <div className="p-space-2 grid gap-space-1">
        <h4 className="text-grey-100 text-m">{subject}</h4>
        <p className="text-grey-400 text-s">{summary}</p>
        <div className="py-space-2 self-end flex">
          <button
            className="flex-grow text-grey-100 text-s leading-none rounded-xs border-orange-800 bg-orange-800/30"
            onClick={() => setModal(message)}
          >
            {t("button.show-me")}
          </button>
        </div>
      </div>
    </div>
  );
};

const InboxMessage = {
  ActionMessage,
};

export { InboxMessage };
