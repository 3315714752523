import React from "react";
import { getCurrencyIcon } from "src/utils/image";
import { convertTokenName } from "src/utils";

interface IModalGSConvertForm {
  amount: number;
  setAmount: (val: number) => void;
  amounts: number[];
  tokenName: string | null;
  step: string;
  tokenValue: number;
}

export const ModalGSConvertForm = (props: IModalGSConvertForm) => {
  const { amount, setAmount, amounts, tokenName, step, tokenValue } = props;
  const selectedCurrency =
    step === "select_currency" ? "grey-800" : "orange-800";

  const handleOnChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    if (!isNaN(Number(value)) && /^\d*\.?\d*$/.test(value)) {
      if (Number(value) > (tokenValue || 0)) {
        setAmount(tokenValue);
        return;
      }
      setAmount(Number(value));
    }
  };

  const handleOnClickAmount = (val: number, tokenValue: number) => {
    if (val > tokenValue) {
      setAmount(tokenValue);
      return;
    }

    setAmount(val);
  };

  return (
    <div className="relative flex flex-col gap-space-4 rounded-s px-space-4 py-space-2 overflow-hidden overflow-y-auto scrollbar-hide scrollbar-hidden w-full">
      {/* Create a blacked background img */}
      <div
        className="absolute inset-0 before:content-[''] before:absolute before:inset-0 before:bg-black before:opacity-50"
        style={{
          backgroundImage: "url('/assets/v4/gstar-half-transparent.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 0,
        }}
      ></div>
      {/* Setting z-10 to not being blocked by the bg img*/}
      <div className="flex flex-col gap-space-2 relative z-10">
        <h2 className="text-s md:text-l blender-medium text-grey-100 normal-case">
          Enter amount to convert
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-space-1">
          {amounts.map((val, index) => (
            <button
              key={index}
              onClick={() => handleOnClickAmount(val, tokenValue)}
              className={`flex h-[36px] px-[10px] py-space-2 rounded-xs items-center justify-center
            bg-grey-200/10 font-[500] text-s border-[1px] border-solid
            ${
              amount !== val
                ? "border-black-100 text-black-100"
                : `border-orange-800 text-orange-800`
            }`}
            >
              {val}.00
            </button>
          ))}
        </div>
        <div className="flex flex-col gap-space-1 py-space-1">
          <div className="relative flex items-center ">
            <input
              type="number"
              placeholder="Custom amount"
              value={amount}
              onChange={(e) => handleOnChangeAmount(e)}
              className={`w-full h-[40px] text-left placeholder-left pl-space-4 border-black-200 rounded-xs border-solid border-[1px] placeholder-black-200 text-${selectedCurrency} placeholder-blender-medium blender-medium placeholder-font-[700] placeholder-text-l font-[400] text-l blender-medium bg-transparent placeholder-center placeholder-text-grey-600 focus:border-${selectedCurrency} focus:outline-none`}
            />
            <span className="absolute right-4 text-white blender-medium font-[700] text-s md:text-l flex">
              <img
                src={getCurrencyIcon(tokenName ?? "GS")}
                alt={tokenName ?? "GS"}
                className="w-[20px] h-[20px] pt-space-1 pr-[3px]"
              />
              {amount} {convertTokenName(tokenName ?? "GS")}
            </span>
          </div>
          {tokenValue && (
            <div className="flex flex-row items-center gap-space-2">
              <p className="text-xs md:text-m">
                My {convertTokenName(tokenName ?? "GS")}
              </p>
              <p className="text-xs md:text-m">•</p>
              <p
                className="text-xs md:text-m text-orange-800 cursor-pointer"
                onClick={() => setAmount(tokenValue)}
              >
                {tokenValue}
              </p>
            </div>
          )}
        </div>
        <div
          className="relative flex-col justify-start"
          style={{ marginBottom: "0px" }}
        >
          <h2
            className="text-s md:text-l blender-medium text-grey-100 normal-case"
            style={{ marginBottom: "0px" }}
          >
            Details
          </h2>
          <div className="relative flex justify-between">
            <span className="text-s md:text-s blender-medium text-grey-800 normal-case">
              Exchange rate
            </span>
            <span
              className={`text-s md:text-s blender-medium text-${selectedCurrency} normal-case flex`}
            >
              <img
                src={getCurrencyIcon(tokenName ?? "MAX")}
                alt={tokenName ?? "GS"}
                className="w-[20px] h-[20px] mx-space-1 mb-space-0"
              />
              1 {convertTokenName(tokenName ?? "GS")} : 1 GS
            </span>
          </div>
        </div>
        <div className="relative flex gap-xs items-center">
          <p className="text-grey-100 font-[700] blender-medium text-s md:text-l">
            Receiving Amount
          </p>
          <img
            src="assets/v4/info.png"
            alt="information symbal"
            className="w-[16px] h-[16px] cursor-pointer mt-space-1 ml-auto"
          />
        </div>
        <div className="relative flex items-center pb-space-4">
          <div className="w-full h-[40px] px-space-4 border-black-200 rounded-xs border-solid border bg-transparent flex-row flex  items-center gap-space-2 justify-end">
            <img
              src={getCurrencyIcon("GS")}
              alt={"GS"}
              className="w-[20px] h-[20px] mr-[-3px]"
            />
            <p className={`text-${selectedCurrency}`}>{amount} GS</p>
          </div>
        </div>
      </div>
    </div>
  );
};
