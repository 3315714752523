import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enTranslation } from "./en";
import { tcTranslation } from "./tc";
import { scTranslation } from "./sc";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      ...enTranslation,
    },
  },
  tc: {
    translation: {
      ...tcTranslation,
    },
  },
  sc: {
    translation: {
      ...scTranslation,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("geda-language") || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
