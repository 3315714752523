import { useCallback, useEffect, useState } from "react";
import { ContainerV3 } from "src/components/container-v3";
import { DashboardInventory } from "src/components/dashboard-inventory";
import { DashboardProfile } from "src/components/dashboard-profile";
import { DashboardReferral } from "src/components/dashboard-referral";
import { DashboardTransactionHistory } from "src/components/dashboard-transaction-history";
import { LoadingIconV2 } from "src/components/loading-icon-v2";
import { ModalContainer } from "src/components/modal/modal-container";
import { RoundButton } from "src/components/round-button";
import { useAuthStore } from "src/stores";
import { IDashboardProfile, IPaginationResponse } from "src/types/dashboard";
import {
  DISPLAY_MODE_CASE,
  DISPLAY_MODE_DEFAULT,
  DISPLAY_MODE_SPIN,
  DISPLAY_MODE_MAX_CASE,
} from "src/config/dashboard";
import { useAuth } from "src/components/auth-context";
import { ModalTransfer } from "src/components/modal-trasnfer";
import { useWallet } from "src/hooks/useWallet";

export const DashboardV2 = () => {
  const [data, setData] = useState<IDashboardProfile | null>(null);
  const [dataReceivedDate, setDataReceivedDate] = useState<Date | null>(null);
  const [dashboardTransactions, setDashboardTransactions] = useState<
    any | null
  >(null);
  const [
    dashboardTransactionsReceivedDate,
    setDashboardTransactionsReceivedDate,
  ] = useState<Date | null>(null);
  const [transferModal, setTransferModal] = useState(false);
  const [transferCurrencyModal, setTransferCurrencyModal] = useState(false);
  const [displayMode, setDisplayMode] = useState<string>(DISPLAY_MODE_DEFAULT);
  const [historyMode, setHistoryMode] = useState<"case" | "common">("case");
  const { setDepositModal } = useWallet();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [paginationResponse, setPaginationResponse] =
    useState<IPaginationResponse>({
      currentPage: 1,
      hasNewer: false,
      hasOlder: false,
      totalPages: 1,
    });

  const { loggedIn, setOpenLoginModal } = useAuth();
  const { getDashboardProfile, getDashboardTransactions } = useAuthStore();

  const getDashboardProfileCb = useCallback(() => {
    getDashboardProfile().then((result: any) => {
      if (!result) return;
      setData(result);
      setDataReceivedDate(new Date());
    });
  }, [getDashboardProfile]);

  const getDashboardTransactionsCb = useCallback(() => {
    setDashboardTransactions(null);
    let types: string;

    if (historyMode === "case") types = "sell-item,mystery-box";
    else types = "deposit,deposit-crypto,p2p-transfer";

    getDashboardTransactions(page, pageSize, types).then((result: any) => {
      if (!result) return;
      const { transactions, pagination: paginationResponse } = result;
      setDashboardTransactions(transactions);
      setPaginationResponse(paginationResponse);
      setDashboardTransactionsReceivedDate(new Date());
    });
  }, [getDashboardTransactions, historyMode, page, pageSize]);

  useEffect(() => {
    const hashValue = window.location.hash;
    if (hashValue === "#case") {
      setDisplayMode(DISPLAY_MODE_CASE);
    }
    if (hashValue === "#max-case") {
      setDisplayMode(DISPLAY_MODE_MAX_CASE);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!loggedIn) return;
    getDashboardProfileCb();
  }, [getDashboardProfileCb, loggedIn]);

  useEffect(() => {
    if (!loggedIn) return;
    getDashboardTransactionsCb();
  }, [getDashboardTransactionsCb, loggedIn, page, pageSize]);

  return (
    <ContainerV3>
      <div className="flex flex-col md:flex-row px-space-2 md:px-space-6 py-space-4 md:py-space-6 items-stretch justify-start w-full">
        <div className="absolute top-0 left-0 w-[70vw]">
          <img
            src="/assets/v4/dashboard-bg.png"
            alt="dashboard-bg"
            className="w-full"
          />
        </div>
        {!loggedIn && (
          <>
            <div className="relative z-[90] w-full">
              <div className="p-space-10 flex flex-col justify-center items-center gap-space-4">
                <p className="text-grey-600 text-center">
                  Login <br />
                  to browse your skins
                </p>
                <RoundButton
                  name="Login now"
                  action={() => setOpenLoginModal(true)}
                />
              </div>
            </div>
          </>
        )}

        {loggedIn && !data && (
          <div className="relative z-[90] w-full">
            <div className="p-space-10 flex items-center justify-center">
              <LoadingIconV2 />
            </div>
          </div>
        )}

        {loggedIn && data && (
          <div className="relative z-[90] w-full">
            <div
              className={`${
                displayMode === DISPLAY_MODE_DEFAULT
                  ? "sm:relative z-[1] sm:opacity-100"
                  : "sm:absolute z-[0] sm:opacity-0"
              } top-0 left-0 w-full transition-all duration-500 sm:duration-1000`}
            >
              <div className="flex items-start flex-col sm:flex-row gap-space-2 sm:gap-space-4">
                <div className="w-full sm:w-[455px] sm:min-w-[300px]">
                  {/* Profile */}
                  <DashboardProfile
                    data={data}
                    dataReceivedDate={dataReceivedDate}
                    dashboardDisplayMode={displayMode}
                    setDashboardDisplayMode={setDisplayMode}
                    setDepositModal={setDepositModal}
                    setTransferModal={setTransferModal}
                    setTransferCurrencyModal={setTransferCurrencyModal}
                  />
                </div>
                <div className="flex-[1]">
                  <div
                    className={`grid ${
                      displayMode === DISPLAY_MODE_DEFAULT
                        ? "grid-rows-[1fr]"
                        : "grid-rows-[0fr]"
                    } sm:grid-rows-[1fr] transition-all duration-500`}
                  >
                    <div className="flex flex-col gap-space-2 sm:gap-space-4 overflow-hidden sm:overflow-visible scrollbar-hidden">
                      {/* Transaction History */}
                      <DashboardTransactionHistory
                        dashboardTransactions={dashboardTransactions}
                        dashboardTransactionsReceivedDate={
                          dashboardTransactionsReceivedDate
                        }
                        getDashboardTransactionsCb={getDashboardTransactionsCb}
                        page={page}
                        pageSize={pageSize}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        historyMode={historyMode}
                        setHistoryMode={setHistoryMode}
                        paginationResponse={paginationResponse}
                      />

                      {/* Referral */}
                      <DashboardReferral />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                displayMode === DISPLAY_MODE_SPIN ||
                displayMode === DISPLAY_MODE_CASE ||
                displayMode === DISPLAY_MODE_MAX_CASE
                  ? "sm:relative h-auto z-[1] opacity-100"
                  : "sm:absolute h-0 z-[0] opacity-0"
              } top-0 left-0 w-full sm:h-auto transition-all duration-500 sm:duration-1000 overflow-hidden sm:overflow-visible`}
            >
              <div className="flex flex-row items-stretch gap-space-2 sm:gap-space-4">
                <div className="hidden sm:block sm:w-[455px] sm:min-w-[300px]">
                  {/* Profile */}
                  <DashboardProfile
                    data={data}
                    dataReceivedDate={dataReceivedDate}
                    dashboardDisplayMode={displayMode}
                    setDashboardDisplayMode={setDisplayMode}
                    setDepositModal={setDepositModal}
                    setTransferModal={setTransferModal}
                    setTransferCurrencyModal={setTransferCurrencyModal}
                  />
                </div>

                <div className="flex-[1] overflow-hidden">
                  <div
                    className={`relative top-0 left-0 ${
                      displayMode === DISPLAY_MODE_SPIN ||
                      displayMode === DISPLAY_MODE_CASE ||
                      displayMode === DISPLAY_MODE_MAX_CASE
                        ? "sm:left-[0%]"
                        : "sm:left-[calc(100%-455px)]"
                    } transition-all duration-500 sm:duration-1000`}
                  >
                    <DashboardInventory
                      data={data}
                      dashboardDisplayMode={displayMode}
                      setDashboardDisplayMode={setDisplayMode}
                      getDashboardProfileCb={getDashboardProfileCb}
                      getDashboardTransactionsCb={getDashboardTransactionsCb}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {transferModal && (
        <ModalTransfer
          onCancel={() => setTransferModal(false)}
          data={data}
          getDashboardProfileCb={getDashboardProfileCb}
        />
      )}

      {transferCurrencyModal && (
        <ModalContainer
          setIsModalOpen={setTransferCurrencyModal}
          type={"transfer_currency"}
        />
      )}
    </ContainerV3>
  );
};
