import moment from "moment";
import { Countdown } from "./countdown";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGameStore } from "src/stores";
import { ModalConfirmPrediction } from "./modal-confirm-prediction";
import { openWarningNotification } from "./notification";
import { useAuth } from "./auth-context";

export const GamePredictionRowV2 = (props: any) => {
  const { game, fetchGame, tab } = props;
  const {
    predict_by = null,
    team_a = "Team A",
    team_a_logo = "/assets/GeDa_default.png",
    team_b = "Team B",
    team_b_logo = "/assets/GeDa_default.png",
    result,
    extra,
  } = game;

  const [isConfirmed] = useState<boolean>(false);
  const [isPicked, setIsPicked] = useState<string | null>(null); // team_a / team_b
  const [myPick, setMyPick] = useState<any | null>(null);
  const [bet, setBet] = useState<number>(0);
  const [potentialWinning, setPotentialWinning] = useState<number>(0);
  const [analysis, setAnalysis] = useState<any>({});
  const [openPredictionModal, setOpenPredictionModal] =
    useState<boolean>(false);

  const { loggedIn, setOpenLoginModal } = useAuth();
  const { getMyPick, getPredictionAnalysisV2, getPredictionPotentialV2 } =
    useGameStore();

  const getMyPickCb = useCallback(() => {
    getMyPick(game["_id"]).then((result) => {
      if (!result) return;
      setIsPicked(result?.predict_team);
      setMyPick(result);
    });
  }, [game, getMyPick]);

  useEffect(() => {
    getPredictionAnalysisV2(game?._id).then((result: any) => {
      if (!result) return;
      setAnalysis(result);
    });
  }, [getPredictionAnalysisV2, game]);

  useEffect(() => {
    if (!loggedIn) return;
    getMyPickCb();
  }, [loggedIn, getMyPickCb]);

  useEffect(() => {
    if (!bet || !game || !isPicked) return;
    getPredictionPotentialV2(game?._id, isPicked, bet).then((result) => {
      if (!result) return;
      setPotentialWinning(result);
    });
  }, [bet, game, isPicked, getPredictionPotentialV2]);

  const teamASupportsPerc = useMemo(() => {
    if (analysis?.team_a_supporters === 0 && analysis?.team_b_supporters === 0)
      return 0;
    return (
      (analysis?.team_a_supporters /
        (analysis?.team_a_supporters + analysis?.team_b_supporters)) *
      100
    ).toFixed(2);
  }, [analysis]);

  const teamBSupportsPerc = useMemo(() => {
    if (analysis?.team_a_supporters === 0 && analysis?.team_b_supporters === 0)
      return 0;
    return (
      (analysis?.team_b_supporters /
        (analysis?.team_a_supporters + analysis?.team_b_supporters)) *
      100
    ).toFixed(2);
  }, [analysis]);

  const calculateTimeLeft = () => {
    let difference = +new Date(predict_by) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        D: Math.floor(difference / (1000 * 60 * 60 * 24)),
        H: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Mins: Math.floor((difference / 1000 / 60) % 60),
        // Secs: Math.floor((difference / 1000) % 60),
      };
    }

    if (difference <= 0) {
      // refresh();
      return 0;
    }
    return timeLeft;
  };

  const confirmModal = () => {
    if (!loggedIn) {
      setOpenLoginModal(true);
      return openWarningNotification("Please login to predict the game.");
    }
    if (bet < 100 || bet > 10000)
      return openWarningNotification("G-Points is out of range.");
    if (myPick) return openWarningNotification("You already picked a team.");
    setOpenPredictionModal(true);
  };

  const selectTeam = (team: string) => {
    if (isConfirmed) return;
    if (isPicked === team) return setIsPicked(null);
    setIsPicked(team);
  };

  const getTxt = (result: string) => {
    if (result) return "Winner: ";
    if (calculateTimeLeft()) return "Prediction ends in: ";
    return "Prediction: ";
  };
  // if (!game_time) return null;
  if (tab === "my-predictions") {
    if (!myPick) return null;
  }
  if (!predict_by) return null;

  return (
    <div className="flex flex-col w-full gap-space-1 sm:gap-space-2">
      <div>
        <div className="flex flex-row gap-space-1 items-center">
          {/* Upcoming or Ended */}
          <div className="flex-1 flex flex-row items-center gap-space-1">
            <div
              className={`rounded-full w-[8px] h-[8px] ${
                game?.result ? "bg-native-red" : "bg-native-blue"
              }`}
            />
            <p className="text-s text-grey-400 leading-[14px] sm:leading-[24px] blender-medium">
              {game?.result ? "Ended" : "Upcoming"}
            </p>
          </div>

          {/* Match Details */}
          <div className="flex flex-row gap-space-4 items-center justify-center">
            <p
              className="text-s sm:text-xxl text-grey-800 leading-[14px] sm:leading-[24px] acpex"
              style={{
                ...(game?.result === "team_a" && {
                  color: "#FE5219",
                }),
              }}
            >
              {extra?.team_a || "0"}
            </p>
            <p className="text-s sm:text-l text-native-blue leading-[14px] sm:leading-[24px] blender-medium">
              vs
            </p>
            <p
              className="text-s sm:text-xxl text-grey-800 leading-[14px] sm:leading-[24px] acpex"
              style={{
                ...(game?.result === "team_b" && {
                  color: "#FE5219",
                }),
              }}
            >
              {extra?.team_b || "0"}
            </p>
          </div>

          {/* Game Name */}
          <div className="flex-1 flex flex-col items-end">
            <div className="flex flex-row items-center gap-space-1">
              <p className="text-s sm:text-l text-grey-100 leading-[14px] sm:leading-[19px] blender-medium">
                {game?.name}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-space-2 items-center">
          {/* Prediction ends in */}
          <div className="flex-1 flex flex-row items-center gap-space-1 w-[200px]">
            <p className="text-xs sm:text-s text-grey-400 leading-[10px] sm:leading-[12px] blender-medium">
              {getTxt(game?.result)}
              <span className="text-orange-800">
                {game?.result ? (
                  game[result]
                ) : (
                  <Countdown time={calculateTimeLeft()} />
                )}
              </span>
            </p>
          </div>

          {/* Game Time */}
          <div className="flex-1 flex flex-col items-end w-[200px]">
            <div>
              <p className="text-xs sm:text-s text-grey-400 leading-[10px] sm:leading-[12px] blender-medium">
                {game?.game_time
                  ? moment.utc(game?.game_time).format("ddd, D MMMM HH:mm")
                  : "TBC"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-stretch justify-center gap-space-2 w-full">
        {/* Team A */}
        <div
          className={`${$teamContainer}`}
          onClick={() => selectTeam("team_a")}
          style={{
            ...(isPicked === "team_a" && {
              background: "#A0A0A0",
            }),
            ...(myPick?.predict_team === "team_a" && {
              border: "1px solid #FE5219",
            }),
          }}
        >
          <div className="flex-1 flex flex-row items-center gap-space-1">
            <div className={`${$teamLogoWrapper}`}>
              <img
                src={team_a_logo || "/assets/GeDa_default.png"}
                className={`${$teamLogo}`}
                alt="team-a-logo"
              />
            </div>

            <p className={`${$teamName}`}>{team_a || "Team A"}</p>
          </div>

          {/* Points & Percentage */}
          <div className="flex flex-col">
            <p className="text-xs sm:text-s text-right text-grey-800 leading-[14px] blender-medium">
              {analysis?.team_a_total || "Pening"} pts
            </p>
            <p className="text-xs text-right text-orange-800 leading-[14px] blender-medium">
              x
              {(analysis?.team_a_total / analysis?.team_b_total || 1).toFixed(
                1
              )}
            </p>
            <p className="text-xs sm:text-s text-right text-grey-800 leading-[14px] blender-medium">
              {teamASupportsPerc}% Picked
            </p>
          </div>

          <img
            src={`/assets/v4/${
              analysis?.team_a_total > analysis?.team_b_total ? "more" : "less"
            }_donut.png`}
            className="w-[16px] sm:w-[34px] h-[16px] sm:h-[34px]"
            alt="donut"
          />
        </div>
        {/* Team B */}
        <div
          className={`${$teamContainer}`}
          onClick={() => selectTeam("team_b")}
          style={{
            ...(isPicked === "team_b" && {
              background: "#A0A0A0",
            }),
            ...(myPick?.predict_team === "team_b" && {
              border: "1px solid #FE5219",
            }),
          }}
        >
          <div className="flex-1 flex flex-row items-center gap-space-1">
            <div className={`${$teamLogoWrapper}`}>
              <img
                src={team_b_logo || "/assets/GeDa_default.png"}
                className={`${$teamLogo}`}
                alt="team-b-logo"
              />
            </div>

            <p className={`${$teamName}`}>{team_b || "Team B"}</p>
          </div>

          {/* Points & Percentage */}
          <div className="flex flex-col">
            <p className="text-xs sm:text-s text-right text-grey-800 leading-[14px] blender-medium">
              {analysis?.team_b_total || "Pening"} pts
            </p>
            <p className="text-xs text-right text-orange-800 leading-[14px] blender-medium">
              x
              {(analysis?.team_b_total / analysis?.team_a_total || 1).toFixed(
                1
              )}
            </p>
            <p className="text-xs sm:text-s text-right text-grey-800 leading-[14px] blender-medium">
              {teamBSupportsPerc}% Picked
            </p>
          </div>

          <img
            src={`/assets/v4/${
              analysis?.team_b_total > analysis?.team_a_total ? "more" : "less"
            }_donut.png`}
            className="w-[16px] sm:w-[34px] h-[16px] sm:h-[34px]"
            alt="donut"
          />
        </div>
      </div>

      {isPicked && !myPick && calculateTimeLeft() !== 0 && (
        <>
          {/* Confirmation */}
          <div className="flex flex-col gap-space-4">
            <div className="flex flex-row justify-between items-center">
              <p className="text-m text-grey-800">Enter amount</p>
              <p className="text-m text-grey-800">
                Potential Winnings{" "}
                <span className="text-orange-800">
                  + {potentialWinning} (G-Points)
                </span>
              </p>
            </div>

            {/* Bet Buttons */}
            <div className="flex flex-row items-center gap-space-2">
              <div
                className="flex items-center justify-center border rounded-xs bg-black-800 border-orange-600 w-[120px] h-full cursor-pointer h-[47px]"
                onClick={() => setBet(100)}
              >
                <p className="text-m text-orange-600">MIN - 100</p>
              </div>

              <input
                value={String(bet)}
                onChange={(e) => setBet(Number(e?.target?.value))}
                className="flex items-center justify-center border rounded-xs bg-black-800 border-black-100 h-full cursor-pointer h-[47px] px-space-4 py-space-2 text-black-100 text-m flex-1"
                placeholder="100 - 10,000 (G-Points)"
              />

              <div
                className="flex items-center justify-center border rounded-xs bg-black-800 border-orange-600 w-[120px] h-full cursor-pointer h-[47px]"
                onClick={() => setBet(10000)}
              >
                <p className="text-m text-orange-600">MAX - 10000</p>
              </div>
            </div>

            <p className="text-xs text-native-yellow">
              Minimum entry to predict - 100 (G-Points)
            </p>

            {/* Confirm Btns */}
            <div className="flex flex-row items-center gap-space-2">
              <div
                className="flex items-center justify-center border rounded-xs border-grey-600 bg-black-800 flex-1 h-full cursor-pointer h-[47px]"
                onClick={() => {
                  setIsPicked(null);
                  setBet(0);
                }}
              >
                <p className="text-m text-grey-600 ">Reset</p>
              </div>

              <div
                className="flex items-center justify-center border rounded-xs bg-black-800 border-orange-600 flex-1 h-full cursor-pointer h-[47px]"
                onClick={() => confirmModal()}
              >
                <p className="text-m text-orange-600">Confirm</p>
              </div>
            </div>
          </div>
        </>
      )}

      <ModalConfirmPrediction
        isModalOpen={openPredictionModal}
        setIsModalOpen={setOpenPredictionModal}
        isPicked={isPicked}
        game={game}
        myPick={myPick}
        fetchGame={fetchGame}
        getMyPickCb={getMyPickCb}
        bet={bet}
      />
    </div>
  );
};

const $teamContainer =
  "flex-1 flex flex-row justify-center p-space-2 bg-grey-100 items-center rounded-xs relative overflow-hidden cursor-pointer hover:bg-grey-100/50 transition-all duration-700 relative gap-space-1";

const $teamName =
  "flex-1 text-s text-black-600 leading-[14px] !font-[900] break-words blender-medium";
const $teamLogoWrapper =
  "min-w-[24px] min-h-[24px] rounded-full overflow-hidden relative z-[15] p-space-0 bg-grey-200";
const $teamLogo = "w-[20px] h-[20px] object-cover relative z-[15]";
