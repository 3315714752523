export const eventNotifications = [
  {
    id: "msg01",
    dateTime: new Date().toTimeString(),
    type: "action",
    subject:
      "Join the GEDA Beta Test: Experience Case Opening and Earn Exclusive Test Rewards!",
    summary:
      "We are excited to announce the launch of the new and improved beta version of the GEDA Esports platform! As an important milestone in the GEDA ecosystem, this beta test is open to all users and focuses on evaluating one of our core features: 𝘁𝗵𝗲 𝗚𝗮𝗺𝗲 𝗔𝘀𝘀𝗲𝘁 𝗖𝗮𝘀𝗲 𝗢𝗽𝗲𝗻𝗶𝗻𝗴.",
    content: `We are excited to announce the launch of the new and improved beta version of the GEDA Esports platform! As an important milestone in the GEDA ecosystem, this beta test is open to all users and focuses on evaluating one of our core features: 𝘁𝗵𝗲 𝗚𝗮𝗺𝗲 𝗔𝘀𝘀𝗲𝘁 𝗖𝗮𝘀𝗲 𝗢𝗽𝗲𝗻𝗶𝗻𝗴.<br /><br />GEDA’s case opening feature will collaborate with leading games in the industry. We are delighted to announce that ourfirst partner is the premier metaverse FPS mobile game, Matr1x Fire.<br /><br />During the test period, not only will you experience the unique thrill of GEDA’s case opening feature, but you will also have the opportunity to earn generous test rewards and build advantages for future versions!`,
    imageUrl: "https://media.geda.gg/inbox/event_banner_1.png",
    sitePath: "/mystery-box/66fe8a852409e14f9def24c4",
  },
];
