import { LANGUAGE_LIST } from "src/config/language-list";

interface INavLanguageOptionList {
  handleChangeLanguage: (code: string) => void;
  mobile?: boolean;
}

export const NavLanguageList = (props: INavLanguageOptionList) => {
  const { handleChangeLanguage, mobile = false } = props;

  const mobileListClass =
    "absolute left-0 bottom-14 w-full h-auto bg-black-60 p-space-4 z-[1001]";
  const desktopListClass =
    "w-[230px] h-auto bg-black-600 absolute right-0 top-[82px] p-space-2 border-l border-r border-b border-black-200";
  return (
    <div>
      <div
        className={mobile ? mobileListClass : desktopListClass}
        style={mobile ? {} : { borderRadius: "0 0 8px 8px" }}
      >
        <div className="flex flex-col">
          {LANGUAGE_LIST.filter((item) => item.enabled).map(
            ({ code, name, nativeName }) => (
              <div
                key={code}
                className="flex flex-row justify-between cursor-pointer"
                onClick={() => handleChangeLanguage(code)}
              >
                <p className="blender-medium text-grey-100 text-l font-[500]">
                  {name}
                </p>
                <p className="blender-medium text-grey-800 text-l font-[500]">
                  {nativeName}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
