export const LeaderboardHeader = () => {
  const tStyle =
    "blender-medium font-[700] text-l sm:text-[18px] text-grey-800 leading-none px-space-1";
  return (
    <div className="pt-m pb-s grid grid-cols-15 gap-space-6 w-full">
      <div className={`${tStyle} col-start-2 col-span-3 flex justify-end`}>
        Rank
      </div>
      <div className={`${tStyle} col-start-6 col-span-3 flex justify-center`}>
        Username
      </div>
      <div className={`col-start-10 col-span-4 ${tStyle} flex justify-center`}>
        Total Score
      </div>
    </div>
  );
};
