export const scTranslation = {
  message: {
    "coming-soon": "即将推出",
  },
  nav: {
    "case-open": "开启宝箱",
    "watch-predict": "直播与预测",
    earn: "赚取",
    guild: "公会",
    esports: "电竞",
    connect: "连接",
    dashboard: "个人中心",
    "top-up": "充值",
  },
  inbox: {
    notifications: "通知",
    platform: "平台",
    personal: "个人",
    "no-notifications": "尚无通知",
  },
  button: {
    "mark-all-as-read": "全部标记为已读",
    "show-me": "显示更多",
    try: "立即试用",
    "task-available": "任务可用",
  },
  announcement: {
    won: "赢得",
    with: "以",
    on: "从",
    draw: "开箱",
  },
  contact: {
    "contact-us": "CONTACT US",
  },
  footer: {
    "terms-of-service": "服务条款",
    "privacy-policy": "隐私政策",
    about: "关于",
    description: "区块链驱动的电竞基础设施：",
    description2: "一个革命性的模型，让每个人都能体验和参与电竞。",
    address:
      "Regus HK, 29F, The Gateway Tower 5, Harbour City, 15 Canton Road, TST",
  },
  landing: {
    popular: "热门",
    "watch-predict": "直播与预测",
    "blind-box": "盲盒",
    "open-box": "开箱",
    marketplace: "市场",
    "spin-earn": "转动手柄并赚取",
    "task-available": "任务可用",
    "our-valuable-partners": "我们的宝贵合作伙伴",
    "a-special-thanks-to-all-our-supporters": "对所有支持者的特别感谢",
    "upcoming-predictions": "即将推出的预测",
  },
  general: {
    "coming-soon": "即将推出",
  },
};
