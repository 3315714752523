import { useRef } from "react";
import { useSettings } from "src/hooks/useSettings";
import { ReactComponent as MuteIcon } from "src/svg-icons/white-muted.svg";
import { ReactComponent as UnMuteIcon } from "src/svg-icons/white-unmuted.svg";
import clsx from "clsx";

export const VolumeBar = () => {
  const volumeBar = useRef<HTMLInputElement>(null);
  const { volume, setVolume } = useSettings();

  return (
    <div
      className={clsx(
        "p-4 w-[56px] h-[56px] gap-2 bg-[#242424] opacity-80 overflow-hidden",
        "flex flex-col justify-end items-center",
        "border-[#737373] border-[1px] rounded-[4px]",
        "hover:h-[204px] transition-all duration-500",
        "group"
      )}
    >
      <input
        ref={volumeBar}
        className={clsx(
          "volume-bar",
          "opacity-0",
          "group-hover:opacity-[100]",
          "transition-all duration-500"
        )}
        type="range"
        min="0"
        max="100"
        value={volume}
        style={{
          background: `linear-gradient(to top, #FE5219 0%, #FE5219 ${volume}%, #ffffff ${volume}%, #ffffff 100%)`,
        }}
        onChange={(e) => {
          setVolume(Number(e.target.value));
        }}
      />
      <div onClick={() => setVolume(volume === 0 ? 100 : 0)}>
        {volume === 0 ? <MuteIcon /> : <UnMuteIcon />}
      </div>
    </div>
  );
};
