import { FC, useContext } from "react";
import clsx from "clsx";
import { useSpring, animated, SpringValue } from "@react-spring/web";
import { InboxFolder, InboxFolderOrder, Inbox } from "src/types/inbox";
import { InboxContext } from "./inbox-provider";
import { InboxMessage } from "./inbox-message";
import { t } from "i18next";

interface IMessageListProps {
  className?: String;
  springs: { transform: SpringValue<string> };
  inbox: Inbox;
}

const MessageList: FC<IMessageListProps> = ({ className, springs, inbox }) => {
  const hasMessage = inbox.messages.length > 0;

  return (
    <animated.section
      className={clsx(
        className,
        "relative basis-full shrink-0 h-full gap-space-3 overflow-y-auto scrollbar-hide overscroll-contain"
      )}
      style={springs}
    >
      {!hasMessage && (
        <div className="absolute w-full h-full flex items-center justify-center">
          <span className="text-ml text-grey-800">
            {t("inbox.no-notifications")}
          </span>
        </div>
      )}
      {inbox.messages.map((message) => (
        <InboxMessage.ActionMessage key={message.id} message={message} />
      ))}
    </animated.section>
  );
};

interface IInboxMessageContainerProps {
  className?: String;
  active: InboxFolder;
}

const InboxMessageContainer: FC<IInboxMessageContainerProps> = ({
  className,
  active,
}) => {
  const { inboxes } = useContext(InboxContext);
  const [springs] = useSpring(
    () => ({
      to: {
        transform: `translateX(-${100 * InboxFolderOrder[active]}%)`,
      },
    }),
    [active]
  );

  return (
    <div className={clsx(className, "relative ")}>
      <div className="absolute px-space-2 w-full h-full">
        <div className="flex w-full h-full overflow-clip">
          {inboxes.map((inbox) => (
            <MessageList key={inbox.folder} springs={springs} inbox={inbox} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { InboxMessageContainer };
