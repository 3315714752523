import { ISettingsStore } from "src/types/settings";
import { create } from "zustand";

const useSettingsStore = create<ISettingsStore>((set) => ({
  volume: (() => {
    const settingVolume = localStorage.getItem("geda-settings-volume");
    return settingVolume ? Number(settingVolume) : 100;
  })(),
  setVolume: (volume) => {
    localStorage.setItem("geda-settings-volume", JSON.stringify(volume));
    set({ volume });
  },
}));

export const useSettings = () => {
  const { volume, setVolume } = useSettingsStore();
  return { volume, setVolume };
};
