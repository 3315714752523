import { LoadingIconV2 } from "../loading-icon-v2";
import { history } from "src/stores";
import { DISPLAY_MODE_CASE, DISPLAY_MODE_MAX_CASE } from "src/config/dashboard";
import { formatDollar } from "src/utils";
import { InventoryCaseItem } from "./inventory-case-item";
import { getCurrencyIcon } from "src/utils/image";
import { useMemo, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const $selectionStyle =
  "px-space-2 py-space-2 bg-black-600 hover:bg-black-800 cursor-pointer transition-colors duration-150 text-s sm:text-m text-white";

const SORT_OPTIONS = [
  { label: "Default", value: null },
  { label: "Price", value: "weaponPrice" },
  { label: "Rarity", value: "quality" },
] as const;
interface IInventoryCase {
  dashboardDisplayMode: string;
  weapons: any[] | null;
  loggedIn: boolean;
  loading: boolean;
  totalWeaponPrice: number;
  handleSellAllWeapons: () => void;
  getMyCaseBagCb: () => void;
  getMyCaseBagByCurrencyCb?: (currency: string) => void;
}

export const InventoryCase = ({
  dashboardDisplayMode,
  weapons,
  loggedIn,
  loading,
  totalWeaponPrice,
  handleSellAllWeapons,
  getMyCaseBagCb,
  getMyCaseBagByCurrencyCb,
}: IInventoryCase) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedSort, setSelectedSort] = useState<string | null>(null);
  const isMaxCase = dashboardDisplayMode === DISPLAY_MODE_MAX_CASE;

  const getQualityLevel = (quality: string) => {
    if (quality === "legendary") return 1;
    if (quality === "epic") return 2;
    if (quality === "rare") return 3;
    if (quality === "common") return 4;
    return 0;
  };

  const sortedWeapons = useMemo(() => {
    if (!weapons) return [];
    if (!selectedSort) return weapons;
    if (selectedSort === "weaponPrice") {
      return weapons.sort((a: any, b: any) => {
        return b?.item[selectedSort] - a?.item[selectedSort];
      });
    }
    // Quality is string, so we need to sort by quality level
    // Legendary, Epic, Rare, Common
    if (selectedSort === "quality") {
      return weapons.sort((a: any, b: any) => {
        const qualityLevelA = getQualityLevel(a?.item?.quality);
        const qualityLevelB = getQualityLevel(b?.item?.quality);
        return qualityLevelB - qualityLevelA;
      });
    }
    return weapons;
  }, [weapons, selectedSort]);

  const getSortLabel = (sort: string) => {
    if (sort === "weaponPrice") return "Price";
    if (sort === "quality") return "Rarity";
    return "";
  };

  return (
    <div
      className={`${
        dashboardDisplayMode === DISPLAY_MODE_CASE || isMaxCase
          ? "block"
          : "hidden"
      }`}
    >
      {loggedIn && !weapons && (
        <div className="flex items-center justify-center py-space-8">
          <LoadingIconV2 />
        </div>
      )}

      {loggedIn && weapons && weapons.length === 0 && (
        <div className="flex items-center justify-center">
          <button
            className="blender-medium text-s sm:text-l leading-[16px] sm:leading-[19px] normal-case py-space-2 sm:py-space-3 btn-primary"
            onClick={() => history.push("/mystery-box")}
          >
            Open case
          </button>
        </div>
      )}

      {loggedIn && weapons && weapons.length > 0 && (
        <div className="flex flex-col gap-space-4">
          {/* Search & Sort */}
          <div className="flex flex-row items-center gap-space-2 justify-end">
            <div className="relative">
              <div
                className="flex flex-row items-center gap-space-2 cursor-pointer"
                onClick={() => setIsOpenDropdown(!isOpenDropdown)}
              >
                <p className="text-s sm:text-m text-grey-600">
                  Sort by{" "}
                  {selectedSort && (
                    <span className="text-orange-600">
                      {getSortLabel(selectedSort)}
                    </span>
                  )}
                </p>
                {isOpenDropdown && <IoIosArrowUp className="text-grey-600" />}
                {!isOpenDropdown && (
                  <IoIosArrowDown className="text-grey-600" />
                )}
                {/* Icon of Arrow Down */}
              </div>
              <div
                className={`absolute top-full left-0 w-full bg-white shadow-md rounded-md 
        transform transition-all duration-200 ease-in-out z-10
        ${
          isOpenDropdown
            ? "opacity-100 translate-y-0"
            : "opacity-0 -translate-y-2 pointer-events-none"
        }`}
              >
                {SORT_OPTIONS.map((option) => (
                  <div
                    key={option.label}
                    className={$selectionStyle}
                    onClick={() => {
                      setSelectedSort(option.value);
                      setIsOpenDropdown(false);
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 l:grid-cols-4 2xl:grid-cols-5 gap-space-2 sm:gap-space-4">
            <button
              className="flex flex-col items-center justify-center gap-space-2 border-[1px] border-solid border-orange-600 text-s sm:text-l text-orange-600 leading-[16px] sm:leading-[19px] normal-case py-space-2 sm:py-space-3 rounded-xs"
              onClick={() => handleSellAllWeapons()}
              disabled={loading}
            >
              {loading && <LoadingIconV2 />}
              {!loading && (
                <>
                  <p className="text-s sm:text-l">Sell all weapons</p>
                  <p className="text-s sm:text-l flex flex-row items-center gap-space-1">
                    <img
                      src={getCurrencyIcon(isMaxCase ? "MAX" : "USDT")}
                      alt="sell"
                      className="w-[14px] h-[14px]"
                    />{" "}
                    {formatDollar(totalWeaponPrice)}
                  </p>
                </>
              )}
            </button>
            {sortedWeapons.map((item: any, i: number) => (
              <InventoryCaseItem
                key={i}
                item={item}
                getMyBagCb={
                  isMaxCase && getMyCaseBagByCurrencyCb
                    ? () => getMyCaseBagByCurrencyCb("MAX")
                    : getMyCaseBagCb
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
