import { useState } from "react";
import { ModalClaimForm } from "../modal-claim-form";
import moment from "moment";
import { openWarningNotification } from "../notification";

interface IInventorySpinItem {
  item: any;
  getMyBagCb: any;
}

export const InventorySpinItem = (props: IInventorySpinItem) => {
  const { item, getMyBagCb } = props;

  const btnStyle = `w-full border-[1px] border-solid p-space-2 rounded-xs cursor-pointer mx-auto flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px]`;

  const [isOpenModalClaimForm, setIsOpenModalClaimForm] = useState(false);

  const handleClaim = (item: any) => {
    if (item?.claimed) {
      return;
    }
    if (item?.prize_detail?.game_id === "matrix-firebox") {
      return openWarningNotification(
        "We will distribute to your address soon!"
      );
    }
    setIsOpenModalClaimForm(true);
  };

  const getPrizeName = (item: any) => {
    if (item?.prize_detail?.game_id === "matrix-firebox") {
      if (item?.prize) {
        let tokenName = "$FIRE";
        if (item?.prize_detail?.name.indexOf("USDT") > -1) tokenName = "$USDT";
        return `${item?.prize} ${tokenName}`;
      }
    }

    if (item?.prize_detail?.name) {
      return item?.prize_detail?.name;
    }

    return "Item";
  };

  const getPrizeImg = (item: any) => {
    if (item?.prize_detail?.game_id === "acident8_1") {
      return "/assets/games/BeneCatwiches.png";
    }
    if (item?.prize_detail?.game_id === "acident8gw_1") {
      return "/assets/games/acident8gw.jpeg";
    }
    if (item?.prize_detail?.game_id === "nyannft_1") {
      return "/assets/games/nyan.png";
    }
    if (item?.prize_detail?.game_id === "guardiannft_1") {
      return "/assets/games/nyan_alt.png";
    }
    if (item?.prize_detail?.game_id === "mixmobnft_1") {
      return "/assets/games/mixmob.png";
    }
    if (item?.prize_detail?.game_id === "pomeriumnft_1") {
      return "https://i.seadn.io/s/raw/files/7401eca2d039cdd0f2549542afab569f.png?auto=format&dpr=1&w=384";
    }
    if (item?.prize_detail?.game_id === "pomeriumtoken_1") {
      return "https://pomerium.space/assets/pmg-icon1-f0393b95.gif";
    }
    if (item?.prize_detail?.game_id === "iagentnode_1") {
      return "https://pbs.twimg.com/profile_images/1755138261507534848/ze2Py5L__400x400.jpg";
    }
    if (item?.prize_detail?.game_id === "firebox_1")
      return "/assets/games/firebox.jpeg";
    if (item?.prize_detail?.game_id === "kukubox_1")
      return "/assets/games/kukubox.jpeg";
    if (
      item?.prize_detail?.game_id &&
      item?.prize_detail?.game_id.indexOf("usdt") > -1
    )
      return "/assets/games/usdt_img.jpeg";
    if (item?.prize_detail?.game_id === "ssnft_1")
      return "/assets/games/ssnft.gif";
    if (item?.prize_detail?.game_id === "cellulanft_1")
      return "https://pbs.twimg.com/profile_images/1775784317421318144/PUDhpeaM_400x400.jpg";
    if (item?.prize_detail?.game_id === "bsdwl")
      return "https://pbs.twimg.com/profile_images/1760335903497580544/8t4B7SGH_400x400.jpg";
    return item?.prize_detail?.image;
  };

  const getClaimButtonTxt = (item: any) => {
    if (item?.claimed) {
      return "CLAIMED";
    }
    if (item?.prize_detail?.game_id === "matrix-firebox") {
      return "DISTRIBUTE SOON";
    }
    return "CLAIM";
  };

  return (
    <div
      className="relative p-space-2 rounded-s overflow-hidden"
      style={{
        background: "linear-gradient(180deg, #BCC3DA 0%, #EEF7FB 100%)",
      }}
    >
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          background:
            "linear-gradient(180deg, rgba(46, 46, 46, 0) 50%, rgba(46, 46, 46, 0.3) 75.62%, #2E2E2E 100%)",
        }}
      />
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          background:
            "linear-gradient(360deg, rgba(46, 46, 46, 0) 59.5%, rgba(46, 46, 46, 0.3) 79.75%, #2E2E2E 100%)",
        }}
      />
      <div className="relative overflow-hidden">
        <img
          src={getPrizeImg(item)}
          alt="prize-img-back"
          className="relative mt-[22px] sm:mt-[36px] w-[54%] aspect-square mb-space-2 opacity-0"
        />
        <img
          src={getPrizeImg(item)}
          alt="prize-img"
          className={`absolute transform top-[22px] sm:top-[36px] right-[50%] translate-x-[50%] w-[54%] aspect-square rounded-xs transition-all duration-500`}
        />
        <div className="absolute top-0 left-0 w-full">
          <p className="text-s sm:text-[24px] leading-[14px] sm:leading-[28px] font-[500] blender-medium text-grey-100 mb-space-1 sm:mb-space-2">
            {getPrizeName(item)}
          </p>
          {item?.prize_detail?.type && (
            <p className="small-content">{item?.prize_detail?.type}</p>
          )}
          {item?.created_at && (
            <p className="small-content">
              {moment(item?.created_at).format("DD MMM")}
            </p>
          )}
        </div>
        <div>
          <div className="relative h-[32px] sm:h-[45px]">
            <div className="absolute bottom-0 left-0 w-full">
              <div
                className={`${btnStyle} bg-[#2E2E2E4D] border-grey-800`}
                onClick={() => handleClaim(item)}
              >
                <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200">
                  {getClaimButtonTxt(item)}
                </p>
                <img
                  src="/assets/v4/dashboard-inventory-claim.png"
                  alt="claim"
                  className="w-[10px] h-[10px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalClaimForm
        isModalOpen={isOpenModalClaimForm}
        setIsModalOpen={setIsOpenModalClaimForm}
        item={item}
        getMyBagCb={getMyBagCb}
      />
    </div>
  );
};
