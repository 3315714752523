import React, { useState } from "react";
import { ModalDeposit } from "../modal-deposit";
import { useWindowSize } from "@uidotdev/usehooks";
import { isMobile } from "react-device-detect";
import { ModalTransferCurrency } from "src/components/modal/modal-transfer-currency"; // Import the new modal component

interface IModalContainer {
  setIsModalOpen: (val: boolean) => void;
  type: string;
  extra?: {
    depositCurrency?: string | null;
  };
}

export const ModalContainer: React.FC<IModalContainer> = ({
  setIsModalOpen,
  type,
  extra,
}) => {
  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;
  const [step, setStep] = useState<string>("select_payment"); //select_payment, complete

  const renderTitle = () => {
    if (!type) return "";
    if (type === "deposit") return "Top up request";

    if (type === "transfer_currency") {
      if (step === "select_payment") return "Convert to GS";
      if (step === "complete") return "Complete";
    }
  };

  const renderModal = () => {
    if (!type) return null;
    if (type === "deposit")
      return (
        <ModalDeposit
          setIsModalOpen={setIsModalOpen}
          depositCurrency={extra?.depositCurrency}
        />
      );
    if (type === "transfer_currency")
      return (
        <ModalTransferCurrency
          setIsModalOpen={setIsModalOpen}
          step={step}
          setStep={setStep}
        />
      );
    return null;
  };

  return (
    <div className="w-screen h-screen z-[100] fixed top-0 left-0 flex items-center justify-center">
      <div
        className="gap-space-2 relative z-[10] w-[320px] h-[568px] md:w-[640px] md:h-[590px] overflow-hidden items-start"
        style={{
          backgroundImage: `url("/assets/v4/${
            !isResponsive ? "receipt-bg" : "modal-mobile-bg"
          }.png")`,
          backgroundSize: "cover",
        }}
      >
        <div className="fix px-space-2 py-space-5 gap-space-2">
          <div className="flex flex-row items-center px-space-4 gap-space-2">
            <h2 className="text-gray-100 text-[24px] sm:text-[32px] font-[500]  blender-medium flex-1 pt-space-4 md:pt-space-8 normal-case">
              {renderTitle()}
            </h2>
            <img
              src="/assets/v4/modal-close.png"
              alt="modal-close"
              className="w-[40px] h-[40px] cursor-pointer mt-space-2 md:mt-space-4"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
        </div>
        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />
        {/* Modal Content */}
        {renderModal()}
      </div>

      {/* Overlay */}
      <div className="bg-black-800/60 w-full h-full left-0 absolute"></div>
    </div>
  );
};
