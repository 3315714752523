import { DISPLAY_MODE_SPIN } from "src/config/dashboard";
import { ISellItem } from "src/types/mysteryBox";
import { LoadingIconV2 } from "../loading-icon-v2";
import { history } from "src/stores";
import { InventorySpinItem } from "./inventory-spin-item";

interface IInventorySpin {
  dashboardDisplayMode: string;
  spins: ISellItem[] | null;
  loggedIn: boolean;
  getMyBagCb: () => void;
}

export const InventorySpin = (props: IInventorySpin) => {
  const { dashboardDisplayMode, spins, loggedIn, getMyBagCb } = props;
  return (
    <div
      className={`${
        dashboardDisplayMode === DISPLAY_MODE_SPIN ? "block" : "hidden"
      }`}
    >
      {loggedIn && !spins && (
        <div className="flex items-center justify-center py-space-8">
          <LoadingIconV2 />
        </div>
      )}

      {loggedIn && spins && spins.length === 0 && (
        <div className="flex items-center justify-center">
          <button
            className={`blender-medium text-s sm:text-l leading-[16px] sm:leading-[19px] normal-case py-space-2 sm:py-space-3 btn-primary`}
            onClick={() => history.push("/airdrop")}
          >
            Play Game
          </button>
        </div>
      )}

      {loggedIn && spins && spins.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 l:grid-cols-4 2xl:grid-cols-5 gap-space-2 sm:gap-space-4">
          {spins.map((item: any, i: number) => {
            return (
              <InventorySpinItem item={item} getMyBagCb={getMyBagCb} key={i} />
            );
          })}
        </div>
      )}
    </div>
  );
};
