export const tcTranslation = {
  message: {
    "coming-soon": "即將推出",
  },
  nav: {
    "case-open": "開啟寶箱",
    "watch-predict": "直播與預測",
    earn: "賺取",
    guild: "公會",
    esports: "電競",
    connect: "連接",
    dashboard: "個人中心",
    "top-up": "充值",
  },
  inbox: {
    notifications: "通知",
    platform: "平台",
    personal: "個人",
    "no-notifications": "尚無通知",
  },
  button: {
    "mark-all-as-read": "全部標記為已讀",
    "show-me": "顯示更多",
    try: "立即試用",
    "task-available": "任務可用",
  },
  announcement: {
    won: "贏得",
    with: "以",
    on: "從",
    draw: "開箱",
  },
  contact: {
    "contact-us": "CONTACT US",
  },
  footer: {
    "terms-of-service": "服務條款",
    "privacy-policy": "隱私政策",
    about: "關於",
    description: "區塊鏈驅動的電競基礎設施：",
    description2: "一個革命性的模型，讓每個人都能體驗和參與電競。",
    address:
      "Regus HK, 29F, The Gateway Tower 5, Harbour City, 15 Canton Road, TST",
  },
  landing: {
    popular: "熱門",
    "watch-predict": "觀看與預測",
    "blind-box": "盲盒",
    "open-box": "開啟寶箱",
    marketplace: "市場",
    "spin-earn": "轉動並賺取",
    "task-available": "任務可用",
    "our-valuable-partners": "我們的寶貴合作夥伴",
    "a-special-thanks-to-all-our-supporters": "對所有支持者的特別感謝",
    "upcoming-predictions": "即將推出的預測",
  },
  general: {
    "coming-soon": "即將推出",
  },
};
