import { IDashboardProfile } from "src/types/dashboard";
import { CURRENCIES } from "src/config/currencies";

interface iMobileNavBalanceCurrencies {
  dashboardData: IDashboardProfile | null;
}

export const MobileNavBalanceCurrencies = (
  props: iMobileNavBalanceCurrencies
) => {
  const { dashboardData } = props;

  const currencyIconStyle = `w-[10px] md:w-[14px] h-[10px] md:h-[14px]`;
  const currencyBalanceStyle = `text-s md:text-m leading-[10px] md:leading-[14px] font-[500] blender-medium`;

  return (
    <div className="flex flex-col gap-space-1">
      {CURRENCIES.map((currency, index) => (
        <div key={index} className="flex flex-row gap-space-1 mx-auto">
          <img
            src={currency.icon}
            alt={currency.initial}
            className={currencyIconStyle}
          />
          <p className={currencyBalanceStyle}>
            {dashboardData?.[currency.value as keyof typeof dashboardData] || 0}
          </p>
        </div>
      ))}
    </div>
  );
};
