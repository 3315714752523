export interface IRarityColorObj {
  legendary: string,
  epic: string,
  rare: string,
  uncommon: string,
  common: string,
}

export interface IPropertyColorObj {
  bn: string,
  n: string,
  w: string,
  wo: string,
}

export const rarityColorObj = {
  'legendary': '#FF134B',
  'epic': '#A218EE',
  'rare': '#009CFF',
  'uncommon': '#00C78F',
  'common': '#749BC1',
}

export const propertyColorObj = {
  'bn': '#48C1D9',
  'n': '#27E545',
  'w': '#E4A527',
  'wo': '#EE484E',
}

export const getRarityColor = (rarity: string) => {
  const formattedRarity = rarity.toLowerCase() as keyof IRarityColorObj;
  const color = rarityColorObj[formattedRarity] || "#FFF";

  return color;
};

export const getPropertyColor = (property: string) => {
  const formattedProperty = property.toLowerCase() as keyof IPropertyColorObj;
  const color = propertyColorObj[formattedProperty] || "#FFF";

  return color;
}
