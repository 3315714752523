import { create } from "zustand";

interface IWallet {
  depositModal: boolean;
  depositCurrency: string | null;
  setDepositModal: (isOpen: boolean) => void;
  setDepositCurrency: (currency: string | null) => void;
}

const useWalletStore = create<IWallet>((set) => ({
  depositModal: false,
  depositCurrency: null,
  setDepositModal: (isOpen: boolean) => set({ depositModal: isOpen }),
  setDepositCurrency: (currency: string | null) =>
    set({ depositCurrency: currency }),
}));

export const useWallet = () => {
  const { depositModal, depositCurrency, setDepositModal, setDepositCurrency } =
    useWalletStore();
  return { depositModal, depositCurrency, setDepositModal, setDepositCurrency };
};
