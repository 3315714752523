export const RAFFLES_LIST = [
  {
    name: "$MATR1X Box",
    desc: "Open with MATR1X Keys to win Kuku Case, Pioneer Case, $USDT, or GP!",
    image: "/assets/games/GN17.png",
    isSelectable: true,
    isEnded: false,
    type: ["web3"],
    gameId: "matrix-box",
    logo: "/assets/games/GN17.png",
    avatar: "/assets/games/GN17.png",
    keyType: "matrix_key",
    bannerTitle: () => {
      return (
        <>
          <span className="text-v2primary">$MATRIX BOX</span>: OPEN TO WIN KUKU
          CASE, $USDT & MORE
        </>
      );
    },
  },
  {
    name: "$FIRE Box",
    desc: "MATR1X FIRE Asia Masters 2024 winners exclusive. Open and win $FIRE or $USDT!",
    image: "/assets/v2/Game_02.jpg",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "matrix-firebox",
    logo: "/assets/v2/Game_02.jpg",
    avatar: "/assets/v2/Game_02.jpg",
    keyType: "fire_key",
    bannerTitle: () => {
      return (
        <>
          <span className="text-v2primary">$FIRE BOX</span>: OPEN TO WIN $FIRE
          or $USDT
        </>
      );
    },
  },
];
