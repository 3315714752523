import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { eventNotifications } from "src/config/notifications";
import { InboxFolder, Inbox, IMessage } from "src/types/inbox";

const defaultInbox: Inbox[] = [
  {
    folder: InboxFolder.PLATFORM,
    unread: 0,
    messages: [],
  },
  {
    folder: InboxFolder.PERSONAL,
    unread: 0,
    messages: [],
  },
];

interface IInboxContextProps {
  isInboxOpen: boolean;
  modal: IMessage | null;
  unread: number;
  inboxes: Inbox[];
  setIsInboxOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModal: React.Dispatch<React.SetStateAction<IMessage | null>>;
  readMessage: (id: string) => void;
  readAllMessage: (folder: InboxFolder) => void;
}

const InboxContext = createContext<IInboxContextProps>({
  isInboxOpen: false,
  modal: null,
  unread: 0,
  inboxes: defaultInbox,
  setIsInboxOpen: () => undefined,
  setModal: () => undefined,
  readMessage: () => undefined,
  readAllMessage: () => undefined,
});

interface IInboxProviderProps {
  children: React.ReactElement[];
}

const InboxProvider: React.FC<IInboxProviderProps> = ({ children }) => {
  const [isInboxOpen, setIsInboxOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<IMessage | null>(null);
  const [inboxes, setInboxes] = useState<Inbox[]>(defaultInbox);

  useEffect(() => {
    const newInboxes = [
      {
        ...defaultInbox[0], // Use defaultInbox instead of inboxes
        messages: eventNotifications,
      },
      {
        ...defaultInbox[1], // Use defaultInbox instead of inboxes
      },
    ];

    setInboxes(newInboxes);
  }, []);

  const readMessage = useCallback(
    (id: string) => {
      // Check the message is in the platform inbox
      const platformInbox = inboxes.find(
        (inbox) => inbox.folder === InboxFolder.PLATFORM
      );
      if (platformInbox) {
        const message = platformInbox.messages.find(
          (message) => message.id === id
        );
        // If the message is in the platform inbox, save the message ID to local storage
        if (message) {
          const existingMessages = localStorage.getItem("geda-event-messages");
          const existingMessagesArray = existingMessages
            ? JSON.parse(existingMessages)
            : [];
          existingMessagesArray.push(message.id);
          localStorage.setItem(
            "geda-event-messages",
            JSON.stringify(existingMessagesArray)
          );
        }
      }
    },
    [inboxes]
  );

  const readAllMessage = useCallback(
    (folder: InboxFolder) => {
      // If folder is platform, set all unread to false (With new)
      if (folder === InboxFolder.PLATFORM) {
        const messages =
          inboxes.find((inbox) => inbox.folder === folder)?.messages || [];
        // Save messages to local storage (And not to duplicate existing messages)
        const existingMessages = localStorage.getItem("geda-event-messages");
        const existingMessagesArray = existingMessages
          ? JSON.parse(existingMessages)
          : [];
        // Save the messages ID to local storage
        const newMessagesId = messages.map((message) => message.id);
        const newMessages = newMessagesId.filter(
          (id) => !existingMessagesArray.includes(id)
        );
        localStorage.setItem(
          "geda-event-messages",
          JSON.stringify(newMessages)
        );

        window.location.reload();
      }
    },
    [inboxes]
  );

  // useEffect to check if the message is read (For Platform inbox)
  useEffect(() => {
    const existingMessages = localStorage.getItem("geda-event-messages");
    const existingMessagesArray = existingMessages
      ? JSON.parse(existingMessages)
      : [];

    console.log("existingMessagesArray", existingMessagesArray);

    // Update inboxes folder === platform messages of isUnread to false if the message ID is in the existingMessagesArray
    setInboxes((prev) => {
      const newInboxes = prev.map((inbox) =>
        inbox.folder === InboxFolder.PLATFORM
          ? {
              ...inbox,
              messages: inbox.messages.map((message) => ({
                ...message,
                read: existingMessagesArray.includes(message.id),
              })),
            }
          : inbox
      );
      return newInboxes;
    });
  }, []);

  const countUnread = (inboxes: Inbox[]) => {
    // Check inboxes[] messages[] read is false
    const unread = inboxes.reduce((acc, { messages }) => {
      return acc + messages.filter((message) => !message.read).length;
    }, 0);
    return unread;
  };

  const value = useMemo(
    () => ({
      isInboxOpen,
      modal,
      unread: countUnread(inboxes),
      inboxes,
      setIsInboxOpen,
      setModal,
      readMessage,
      readAllMessage,
    }),
    [isInboxOpen, modal, inboxes, readMessage, readAllMessage]
  );

  return (
    <InboxContext.Provider value={value}>{children}</InboxContext.Provider>
  );
};

export { InboxContext, InboxProvider };
