import { useContext, useEffect, useReducer, useRef } from "react";
import clsx from "clsx";
import { CaseOpeningMysteryBoxItem } from "./case-opening-mystery-box-item";
import { CaseOpeningRouletteContext } from "./case-opening-roulette-provider";
import { shuffleArray } from "src/utils/shuffleArray";
import { IMysteryBoxItem, IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { randomIntegerRange, randomRange } from "src/utils/random";

type IShuffledRouletteBoxItem = {
  key: string;
  name: string;
  quality: string;
  min?: number;
  max?: number;
  weaponPrice?: number;
};

enum RoulettePhase {
  Reset = "Reset",
  Ready = "Ready",
  Rolling = "Rolling",
  StopRolling = "StopRolling",
}

type IRouletteState = {
  boxItemWidthInPixels: number;
  phase: RoulettePhase;
  offsetInPixels: string;
  transition: string;
  shuffledRouletteBoxItems: IShuffledRouletteBoxItem[];
  wrapper: React.MutableRefObject<HTMLInputElement | null>;
};

type IRouletteAction =
  | { type: RoulettePhase.Ready }
  | IResetState
  | { type: RoulettePhase.Rolling; rollingTimeInSeconds: number }
  | { type: RoulettePhase.StopRolling };

interface IGetShuffledRouletteBoxItemsParameters {
  boxItemsLength: number;
  boxItems: IMysteryBoxItem[];
  prize: IMysteryBoxPrizeDetail;
  prizeIndex: number;
}

interface ICreateInitialState extends IGetShuffledRouletteBoxItemsParameters {
  phase?: RoulettePhase;
  boxItemWidthInPixels: number;
  wrapper: React.MutableRefObject<HTMLInputElement | null>;
}

interface IResetState extends IGetShuffledRouletteBoxItemsParameters {
  type: RoulettePhase.Reset;
  boxItemWidthInPixels: number;
}

function getShuffledRouletteBoxItems({
  boxItemsLength,
  boxItems,
  prize,
  prizeIndex,
}: IGetShuffledRouletteBoxItemsParameters): IShuffledRouletteBoxItem[] {
  const array: IShuffledRouletteBoxItem[] = Array(boxItemsLength)
    .fill(null)
    .map((item, index) => {
      const boxItem = boxItems[index % boxItems.length];
      return {
        ...boxItem,
        key: `${boxItem.name}_${Math.floor(index / boxItems.length)}`,
      };
    });

  const result = shuffleArray(array);

  const prizeInBoxItems = boxItems.find(
    (item) => item.name === prize.name
  ) as IMysteryBoxItem;

  result[prizeIndex] = {
    ...prizeInBoxItems,
    weaponPrice: prize.weaponPrice!,
    key: "prize",
  };

  return result;
}

const maxNumOfVisibleBoxItems = 7;
const numOfReservedBoxItems = 1;
const maxRouletteWidth = (boxItemWidthInPixels: number) =>
  boxItemWidthInPixels * maxNumOfVisibleBoxItems;

function reducer(
  state: IRouletteState,
  action: IRouletteAction
): IRouletteState {
  const transitionTimingFunction = `cubic-bezier(0.01, 0.74, 0.43, 1)`;

  switch (action.type) {
    case RoulettePhase.Reset: {
      const {
        boxItemWidthInPixels,
        boxItemsLength,
        boxItems,
        prize,
        prizeIndex,
      } = action;

      return createInitialState({
        phase: RoulettePhase.Ready,
        boxItemWidthInPixels,
        boxItemsLength,
        boxItems,
        prize,
        prizeIndex,
        wrapper: state.wrapper,
      });
    }

    case RoulettePhase.Ready: {
      return {
        ...state,
        phase: RoulettePhase.Ready,
      };
    }

    case RoulettePhase.Rolling: {
      const offsetFromCenter = 0.4;
      const rangeMin =
        maxNumOfVisibleBoxItems + numOfReservedBoxItems - offsetFromCenter;
      const rangeMax =
        maxNumOfVisibleBoxItems + numOfReservedBoxItems + offsetFromCenter;
      const range = randomRange(rangeMin, rangeMax);
      const width = state.wrapper.current?.clientWidth || 0;
      const randomOffsetWithinRange = `${
        -width + state.boxItemWidthInPixels * range
      }px`;

      return {
        ...state,
        phase: RoulettePhase.Rolling,
        offsetInPixels: randomOffsetWithinRange,
        transition: `transform ${action.rollingTimeInSeconds}s ${transitionTimingFunction}`,
      };
    }
    case RoulettePhase.StopRolling: {
      const maxReservedWidth =
        state.boxItemWidthInPixels * numOfReservedBoxItems;
      const width = state.wrapper.current?.clientWidth || 0;
      const centerOffset = `${
        -width + maxRouletteWidth(state.boxItemWidthInPixels) + maxReservedWidth
      }px`;

      return {
        ...state,
        phase: RoulettePhase.StopRolling,
        offsetInPixels: centerOffset,
        transition: `transform 0.5s ${transitionTimingFunction} 0.5s`,
      };
    }
    default:
      throw new TypeError("Unknown action");
  }
}

function createInitialState({
  phase = RoulettePhase.Reset,
  boxItemWidthInPixels = 0,
  boxItemsLength,
  boxItems,
  prize,
  prizeIndex,
  wrapper,
}: ICreateInitialState): IRouletteState {
  const initialOffset = `-${
    boxItemWidthInPixels * randomIntegerRange(0, 14)
  }px`;

  return {
    boxItemWidthInPixels,
    phase,
    offsetInPixels: initialOffset,
    transition: "none",
    shuffledRouletteBoxItems: getShuffledRouletteBoxItems({
      boxItemsLength,
      boxItems,
      prize,
      prizeIndex,
    }),
    wrapper,
  };
}

interface ICaseOpeningRoulette {
  prize: IMysteryBoxPrizeDetail;
  boxWidth?: number;
  boxItemClassName?: string;
  currency?: string;
}

export const CaseOpeningRoulette = ({
  prize,
  boxWidth = 200,
  boxItemClassName,
  currency = "USDT",
}: ICaseOpeningRoulette) => {
  const wrapperRef = useRef<HTMLInputElement | null>(null);
  const { boxItems, rollingTimeInSeconds, isRolling, isHighlightPhase } =
    useContext(CaseOpeningRouletteContext);
  const boxItemWidthInPixels = boxWidth;

  /**
   * TODO: boxItemsLength 不能太低，否則 spin 的動畫會跑不起來
   *   (即 translateX 的位移量不夠多)，但缺點是會有多餘的 item 被渲染
   */
  const boxItemsLength = 80;

  // 例如：顯示 7 個，正中間為最後第 4 個，然後再預留 1 個用於回彈
  const prizeIndex =
    boxItemsLength -
    numOfReservedBoxItems -
    Math.ceil(maxNumOfVisibleBoxItems / 2);

  const [state, dispatch] = useReducer(
    reducer,
    createInitialState({
      boxItemWidthInPixels,
      boxItemsLength,
      boxItems,
      prize,
      prizeIndex,
      wrapper: wrapperRef,
    })
  );

  const { phase, offsetInPixels, transition, shuffledRouletteBoxItems } = state;

  useEffect(() => {
    if (isRolling) {
      if (phase === RoulettePhase.StopRolling) {
        dispatch({
          type: RoulettePhase.Reset,
          boxItemWidthInPixels,
          boxItemsLength,
          boxItems,
          prize,
          prizeIndex,
        });
      } else {
        dispatch({ type: RoulettePhase.Ready });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRolling]);

  useEffect(() => {
    if (isRolling && phase === RoulettePhase.Ready) {
      dispatch({ type: RoulettePhase.Rolling, rollingTimeInSeconds });
    }

    if (!isRolling && phase === RoulettePhase.Rolling) {
      dispatch({ type: RoulettePhase.StopRolling });
    }
  }, [isRolling, phase, rollingTimeInSeconds]);

  const prizeScale = 1.1;
  const rouletteBoxItems = shuffledRouletteBoxItems.map((boxItem, index) => {
    const isPrize = index === prizeIndex;
    const isHighlightItem = isHighlightPhase && isPrize;
    const price =
      isHighlightItem && "weaponPrice" in boxItem
        ? `${boxItem.weaponPrice?.toFixed(2)}`
        : "";
    const item = {
      ...boxItem,
      price,
    };

    return (
      <CaseOpeningMysteryBoxItem
        key={boxItem.key}
        className={clsx(boxItemClassName, "duration-300", {
          "scale-110": isHighlightItem,
          "z-10": isHighlightItem,
        })}
        item={item}
        width={boxItemWidthInPixels}
        currency={currency}
      />
    );
  });

  const winningPointer = (
    <div
      className={clsx(
        "absolute left-1/2 transform -translate-x-1/2 z-[1] w-[2px] transition-opacity bg-[linear-gradient(rgba(240,121,121,0),#fe622e_20%,#fe622e_80%,rgba(240,121,121,0))] before:absolute before:top-0 before:left-1/2 before:-translate-x-1/2 before:border-l-[7px] before:border-r-[7px] before:border-t-[8px] before:border-l-transparent before:border-r-transparent before:border-t-orange-600 before:z-[10]",
        {
          "opacity-0 delay-1000 duration-500": !isRolling || isHighlightPhase,
        }
      )}
      style={{
        height: `${prizeScale * 100}%`,
      }}
    />
  );

  const backdrop = (
    <div
      className={clsx(
        "absolute w-full h-full top-0 left-0 rounded-m duration-300",
        {
          "bg-black/75": isHighlightPhase,
        }
      )}
    />
  );

  return (
    <div className="flex items-center justify-center relative overflow-x-clip overflow-y-visible">
      {winningPointer}
      <div>
        <div
          className={`w-full bg-[#11141f] flex items-center rounded-lg overflow-y-visible overflow-x-clip`}
          style={{
            maxWidth: `${maxRouletteWidth(boxItemWidthInPixels)}px`,
          }}
        >
          <div
            ref={wrapperRef}
            className="flex will-change-transform"
            style={{
              transform: `translateX(${offsetInPixels})`,
              transition,
            }}
          >
            {rouletteBoxItems}
            {backdrop}
          </div>
        </div>
      </div>
    </div>
  );
};
