import { useState } from "react";
import { history } from "src/stores";
import { AuthStore } from "src/stores/auth";
import { IconButton } from "../icon-button";
import { RoundButton } from "../round-button";
import { SlWallet } from "react-icons/sl";
import { useLocation } from "react-router";
import { useAuth } from "../auth-context";
import { Label } from "../label";
import clsx from "clsx";
import { NavLanguageList } from "./nav-language-list";
import { PiTranslate } from "react-icons/pi";
import { IoIosArrowUp } from "react-icons/io";
import { t } from "i18next";
import { useLanguage } from "src/hooks/useLanguage";
import { CURRENCIES } from "src/config/currencies";
import { useWallet } from "src/hooks/useWallet";
import { Token } from "src/types/currency";
import { openWarningNotification } from "../notification";

interface INavV3 {
  withBg: boolean;
  isFixedNav: boolean;
}

export const NavV3 = (props: INavV3) => {
  const { isFixedNav = false } = props;
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const {
    languageLabel,
    handleChangeLanguage,
    openLanguageList,
    setOpenLanguageList,
  } = useLanguage();

  const location = useLocation();
  const authStore = new AuthStore();
  const {
    loggedIn,
    setLoggedIn,
    setUserInfo,
    setOpenLoginModal,
    dashboardData,
  } = useAuth();
  const { setDepositModal, setDepositCurrency } = useWallet();

  const isActive = (name: string) => {
    return location?.pathname.indexOf(name) > -1;
  };

  const handleHoverButton = (name: string, isHovered: boolean) => {
    isHovered ? setHoveredButton(name) : setHoveredButton(null);
  };

  const handleLogout = () => {
    authStore.logout();
    setLoggedIn(false);
    setUserInfo(null);
  };

  const handleLogin = () => {
    setOpenLoginModal(true);
  };

  const handleTopUp = () => {
    if (!loggedIn)
      return openWarningNotification(t("message.please-login-first"));
    setDepositModal(true);
    setDepositCurrency(Token.GSTAR);
  };

  return (
    <div
      className={`desktop w-full h-[72px] flex flex-row z-[100] items-stretch justify-start 
      } ${isFixedNav ? "fixed left-0 top-0" : "relative"} bg-black-400/50`}
      style={{
        backdropFilter: "blur(4px)",
        backgroundImage: `url("/assets/nav/nav-bg-transparent.png")`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-row items-center justify-start flex-1 pl-space-4 gap-space-1 l:gap-space-2 w-[60px] h-[100%]">
        <div
          className={clsx("relative h-[100%] flex items-center", {
            "nav-btn-hovered is-logo": hoveredButton === "Home",
          })}
        >
          <img
            src={
              hoveredButton === "Home"
                ? "/assets/v3/geda-orange.png"
                : "/assets/v3/geda-white.png"
            }
            alt="icon"
            className="w-[40px] h-[40px] cursor-pointer"
            onClick={() => history.push("/")}
            onMouseOver={() => handleHoverButton("Home", true)}
            onMouseOut={() => handleHoverButton("Home", false)}
          />
        </div>

        <div className="px-space-3 l:px-space-4 h-[100%] flex flex-row items-center gap-space-1 relative">
          <IconButton
            icon={`/assets/v4/${
              isActive("/mystery-box") || hoveredButton === "Case Open"
                ? `reward-active.png`
                : `reward.png`
            }`}
            name={t("nav.case-open")}
            page={"/mystery-box"}
            isActive={isActive("/mystery-box")}
            isHovered={hoveredButton === "Case Open"}
            onHoverChange={(isHovered) =>
              handleHoverButton("Case Open", isHovered)
            }
          />
          <Label name="Hot" />
        </div>

        <div className="px-space-3 l:px-space-4 h-[100%]">
          <IconButton
            icon={`/assets/v4/${
              isActive("/prediction") || hoveredButton === "Watch & Predict"
                ? `watch-active.png`
                : `watch.svg`
            }`}
            name={t("nav.watch-predict")}
            page={"/prediction"}
            isActive={isActive("/prediction")}
            isHovered={hoveredButton === "Watch & Predict"}
            onHoverChange={(isHovered) =>
              handleHoverButton("Watch & Predict", isHovered)
            }
          />
        </div>

        <div className="px-space-3 l:px-space-4 h-[100%]">
          <IconButton
            icon={`/assets/v4/${
              isActive("/airdrop") || hoveredButton === "Earn"
                ? `earn-active.png`
                : `earn.svg`
            }`}
            name={t("nav.earn")}
            page={"/airdrop"}
            isActive={isActive("/airdrop")}
            isHovered={hoveredButton === "Earn"}
            onHoverChange={(isHovered) => handleHoverButton("Earn", isHovered)}
          />
        </div>

        <div className="px-space-3 l:px-space-4 h-[100%] flex flex-row items-center gap-space-1 relative">
          <IconButton
            name={t("nav.guild")}
            iconProps={"FaGuilded"}
            isHovered={hoveredButton === "Guild"}
            onHoverChange={(isHovered) => handleHoverButton("Guild", isHovered)}
          />
          <Label name="Soon" type="coming-soon" />
        </div>

        <div className="px-space-3 l:px-space-4 h-[100%] flex flex-row items-center gap-space-1 relative">
          <IconButton
            name={t("nav.esports")}
            iconProps={"MdSportsEsports"}
            isHovered={hoveredButton === "Esports"}
            onHoverChange={(isHovered) =>
              handleHoverButton("Esports", isHovered)
            }
          />
          <Label name="Soon" type="coming-soon" />
        </div>
      </div>
      {/* right side gradient */}
      <div className="pr-space-4">
        <div className="relative z-[5] flex flex-row items-center justify-end gap-space-1 l:gap-space-4 h-full ">
          {!loggedIn && (
            <>
              <RoundButton
                name={t("nav.connect")}
                action={() => handleLogin()}
                icon="/assets/v4/nav_wallet.png"
                iconSx={`w-[12px] l:w-[24px] h-[12px] l:h-[24px]`}
                nameSx={`text-s l:text-l leading-[12px] l:leading-[19px] font-[700] blender-medium`}
              />
            </>
          )}

          {loggedIn && (
            <>
              <div className="relative">
                <div className="flex items-center flex-row h-[40px] l:h-[42px]">
                  <div className="bg-black-600 border-[1px] border-solid border-black-200 rounded-full p-space-2">
                    <div className="flex items-center flex-row gap-space-2 group">
                      {CURRENCIES.map((currency, index) => (
                        <div
                          key={index}
                          className={`flex items-center flex-row gap-space-1 ${
                            currency.isHidden ? "hidden group-hover:flex" : ""
                          }`}
                        >
                          <img
                            src={currency.icon}
                            alt={currency.initial}
                            className="w-[12px] h-[12px]"
                          />
                          <p className="text-s l:text-l leading-[17px] l:leading-[16px] font-[700] blender-medium">
                            {dashboardData?.[
                              currency.value as keyof typeof dashboardData
                            ] || 0}
                          </p>
                        </div>
                      ))}

                      <div className="w-[60px] l:w-[85px]" />
                    </div>
                  </div>
                </div>
                <div
                  className="absolute top-0 right-0 px-space-2 l:px-space-3 py-space-3 l:py-space-3 rounded-full cursor-pointer mx-auto flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px] bg-native-green"
                  onClick={() => handleTopUp()}
                >
                  <SlWallet className="w-[12px] l:w-[16px] h-[12px] l:h-[16px] text-white" />
                  <p className="text-s l:text-l leading-[16px] l:leading-[18px] font-[700] blender-medium">
                    {t("nav.top-up")}
                  </p>
                </div>
              </div>
              <div className="relative flex flex-col ">
                <button
                  onClick={() => setOpenLanguageList(!openLanguageList)}
                  className="flex flex-row items-center justify-center cursor-pointer py-space-2 l:py-space-3 px-space-4 bg-black-600 border-[1px] border-solid border-black-200 rounded-full gap-space-1 text-s l:text-l leading-[12px] l:leading-[19px] font-[700] blender-medium normal-case"
                >
                  {languageLabel}
                  {openLanguageList ? <IoIosArrowUp /> : <PiTranslate />}
                </button>

                {openLanguageList && (
                  <NavLanguageList
                    handleChangeLanguage={handleChangeLanguage}
                  />
                )}
              </div>

              <RoundButton
                name={t("nav.dashboard")}
                action={() => history.push("/dashboard")}
                icon="/assets/v4/dashboard.png"
                iconSx={`w-[12px] l:w-[24px] h-[12px] l:h-[24px]`}
                nameSx={`text-s l:text-l leading-[12px] l:leading-[19px] font-[700] blender-medium`}
              />
              <RoundButton
                name={""}
                action={() => handleLogout()}
                icon="/assets/v4/logout.png"
                iconSx={`w-[12px] l:w-[24px] h-[12px] l:h-[24px]`}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
