import { useState } from "react";
import { ContainerV3 } from "src/components/container-v3";
import { CaseOpeningBoxCardList } from "src/components/case-opening-box-card-list";
import { ICaseOpeningFilters } from "src/types/case-opening";
import { CaseOpeningFilter } from "src/components/case-opening-filter";

const caseData = [
  {
    type: "usdt",
    imgSrc: "/assets/v4/geda-star.png",
    title: "Geda Case (Star)",
  },
  {
    type: "max",
    imgSrc: "/assets/v4/case-opening-matr1x-coin-icon.png",
    title: "MATR1X Case (GMAX)",
  },
  {
    type: "gdd",
    imgSrc: "/assets/v4/gdd.png",
    title: "GeDa Diamond Case (GDD)",
  },
];

export const MarketplaceMysteryBoxList = () => {
  const [filters, setFilters] = useState<ICaseOpeningFilters>({});

  return (
    <ContainerV3>
      <div className="flex flex-col md:flex-row p-space-2 md:p-space-4 items-stretch justify-start">
        <div className="md:px-space-4 flex-1 flex flex-col relative ">
          <div className="flex pb-space-4 ">
            <h1 className="text-l md:text-xxxxxl blender-medium text-grey-600 font-[900] leading-none">
              Case Opening
            </h1>
          </div>

          {/* Filtering row */}
          <CaseOpeningFilter filters={filters} setFilters={setFilters} />
          {/* Case Opening Box Case List */}
          <div className="grid gap-space-6">
            {caseData.map((caseItem) => (
              <CaseOpeningBoxCardList
                key={caseItem.type}
                type={caseItem.type}
                title={
                  <div className="flex items-center mb-space-4">
                    <img
                      src={caseItem.imgSrc}
                      className="w-[20px] h-[20px] mr-space-1"
                      alt=""
                    />
                    <p className="text-l md:text-xxml blender-medium font-[900] uppercase leading-none">
                      {caseItem.title}
                    </p>
                  </div>
                }
                filters={filters}
              />
            ))}
          </div>
        </div>
      </div>
    </ContainerV3>
  );
};
