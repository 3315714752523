import clsx from "clsx";
import { IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { CaseOpeningSelectOption } from "./case-opening-select-option";

interface ICaseOpeningSelectList {
  className?: string;
  data: IMysteryBoxPrizeDetail[];
  isValuable: boolean[];
  onSell: (type: "sell-selected" | "sell-all", index?: number) => void;
}

const CaseOpeningSelectList = ({
  className,
  data,
  isValuable,
  onSell,
}: ICaseOpeningSelectList) => {
  return (
    <div className={clsx(className, "grid gap-space-1 md:gap-space-4")}>
      {data.map((item, index) => {
        return (
          <CaseOpeningSelectOption
            key={index}
            item={item}
            isValuableWeapon={isValuable[index]}
            onSell={() => {
              onSell("sell-selected", index);
            }}
          />
        );
      })}
    </div>
  );
};

export { CaseOpeningSelectList };
