interface ILeaderboardRow {
  item: any;
  isUser: boolean;
}

const tStyle = "blender-medium font-[500] text-s text-grey-100";

const getBackgroundColor = (index: number) => {
  if (index === 1) return "#FFC71E1A";
  if (index === 2) return "#C5C5C51A";
  if (index === 3) return "#C36D481A";
  return "#191919CC";
};

const getUsernameMargin = (rank: number) => {
  if (rank < 10) return "ml-space-3";
  if (rank >= 10 && rank < 100) return "ml-space-2";
  return "";
};

export const LeaderboardRow = (props: ILeaderboardRow) => {
  const { item, isUser } = props;

  return (
    <div>
      {/* row */}
      <div
        className={`flex items-center h-[24px] ${
          isUser ? "border-[1px] border-orange-800 " : "mb-space-2 "
        }  `}
        style={{
          backgroundColor: getBackgroundColor(item.rank),
          borderRadius: "16px",
        }}>
        <div className="grid grid-cols-15 gap-space-5 w-full">
          {/* avatar */}
          <div className="flex items-center justify-start col-span-2">
            <img src={item.avatar} alt="avatar" className="w-[24px] h-[24px]" />
          </div>
          {/* rank */}
          <div
            className={`flex items-center justify-start ${tStyle} col-start-3 col-span-1 `}>
            <span>{isUser && item.rank > 100 ? "100+" : item.rank}</span>
          </div>
          <div className="flex items-center ">
            {item.rankImage && (
              <img
                src={item.rankImage}
                alt="ranking"
                className="w-[16px] h-[16px] "
              />
            )}
            {!item.rankImage && <div className="w-[16px] h-[16px] "></div>}
          </div>
          {/* username */}
          <div
            className={`${tStyle} flex items-center justify-start col-start-5 col-span-7 ${getUsernameMargin(
              item.rank
            )}`}>
            {item.displayName}
          </div>
          {/* total score */}
          <div
            className={`${tStyle} flex items-center justify-start col-start-12 col-span-4`}>
            {item.totalAmount}
          </div>
        </div>
      </div>
      {/* bottom line */}
      {isUser && (
        <div className="flex justify-center w-full  ">
          <img
            src="/assets/v4/leaderboard-line.png"
            alt="ranking"
            className="w-[344px]  mt-space-2 !bg-black-400"
          />
        </div>
      )}
    </div>
  );
};
