import clsx from "clsx";
import { useLocation } from "react-router";
import { history } from "src/stores";
import {
  MdOutlineLocationOn,
  MdOutlineMail,
  MdOutlinePrivacyTip,
} from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { t } from "i18next";

interface iFooterV3 {}

export const FooterV3 = (props: iFooterV3) => {
  const location = useLocation();
  const isActive = (name: string) => {
    return location?.pathname.indexOf(name) > -1;
  };

  return (
    <div className="mx-0 px-space-4 md:px-space-6 bg-black overflow-hidden">
      <div className="h-[1px] my-space-8 bg-gradient-to-r from-neutral-500/0 via-neutral-500 to-neutral-500/0" />
      <div className="mb-space-8 overflow-hidden">
        <div className="flex gap-space-6 mb-space-6 text-grey-100 text-m sm:text-[18px]">
          <div
            className={clsx(
              "flex gap-space-2 items-center cursor-pointer transition-transform",
              "hover:text-orange-800 hover:skew-y-2",
              { "text-orange-800": isActive("/tos") }
            )}
            onClick={() => history.push("/tos")}
          >
            <CgNotes />
            {t("footer.terms-of-service")}
          </div>
          <div className="w-[1px] bg-gradient-to-b from-white/0 via-white to-white/0" />
          <div
            className={clsx(
              "flex gap-space-2 items-center cursor-pointer transition-transform",
              "hover:text-orange-800 hover:skew-y-2",
              { "text-orange-800": isActive("/privacy") }
            )}
            onClick={() => history.push("/privacy")}
          >
            <MdOutlinePrivacyTip />
            {t("footer.privacy-policy")}
          </div>
        </div>
        <div className="float-left">
          <div className="flex flex-col gap-space-2 md:gap-space-4 mb-space-6 sm:mb-0">
            <p className="text-l leading-[24px] blender-medium">
              {t("footer.about")} <span className="acpex">Geda</span>
            </p>
            <p className="text-s md:text-m text-grey-400 leading-[22px] blender-medium">
              {t("footer.description")} <br />
              {t("footer.description2")}
            </p>
            <p className="flex flex-col gap-space-2 text-s md:text-m text-grey-400 leading-[12px] md:leading-[14px] blender-medium mt-space-1">
              <span className="flex flex-row gap-space-1 items-top">
                <MdOutlineLocationOn className="min-w-[12px] md:min -w-[14px]" />
                {t("footer.address")}
              </span>
              <a
                className="inline-block text-s md:text-m hover:text-orange-800 leading-[12px] md:leading-[14px] blender-medium transition-transform hover:skew-y-2"
                href="mailto:info@geda.gg"
              >
                <span className="flex flex-row gap-space-1 items-top">
                  <MdOutlineMail className="min-w-[12px] md:min-w-[14px]" />
                  info@geda.gg
                </span>
              </a>
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center float-right w-full sm:w-[240px] md:w-[320px] sm:h-[100px]">
          <div className="flex-[1]"></div>
          <a
            href="https://twitter.com/GEDAEsports"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/v4/twitter.png"
              alt="twitter"
              className={$footerSocialIconStyle}
            />
          </a>
          <div className="flex-[2]"></div>
          <a
            href="https://discord.gg/gedaesports"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/v4/discord.png"
              alt="twitter"
              className={$footerSocialIconStyle}
            />
          </a>
          <div className="flex-[2]"></div>
          <a
            href="https://www.youtube.com/@GeDaEsports"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/v4/youtube.png"
              alt="twitter"
              className={$footerSocialIconStyle}
            />
          </a>
          <div className="flex-[2]"></div>
          <a
            href="https://twitch.tv/gedaesports"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/v4/twitch.png"
              alt="twitch"
              className={$footerSocialIconStyle}
            />
          </a>
          <div className="flex-[1]"></div>
        </div>
        <div className="clear-both"></div>
      </div>
    </div>
  );
};

const $footerSocialIconStyle = "w-[24px] object-cover aspect-square";
