import moment from "moment";
import { useWindowSize } from "@uidotdev/usehooks";
import { formatDollar, formatName } from "src/utils";
import { getCurrencyIcon } from "src/utils/image";

const tdStyle = `align-top pt-space-4`;

interface iDashboardTransactionHistoryCommonRow {
  item: any;
}

export const DashboardTransactionHistoryCommonRow = (
  props: iDashboardTransactionHistoryCommonRow
) => {
  const { item } = props;

  const size: any = useWindowSize();

  const isShowLess = () => {
    return size?.width < 1080;
  };

  const getRenderStatus = (status: string) => {
    if (status === "pending-payment") {
      return "pending";
    }

    if (status === "payment_success") {
      return "done";
    }

    return status;
  };

  const formatDate = (date: Date) => {
    return moment(date).format("MMMM D, YYYY");
  };

  return (
    <tr>
      <td className={`${tdStyle} text-left`}>
        <div className="flex flex-col gap-space-1">
          <div className="flex flex-row gap-space-2">
            <div className="border-[1px] border-solid border-native-green rounded-full w-[24px] h-[24px] p-space-1">
              <img
                src="/assets/v4/dashboard-topup.png"
                alt="prize-img"
                className="w-[12px] h-[12px] aspect-square mx-auto"
              />
            </div>
            <p className="text-l leading-[24px] blender-medium">
              {!item?.type
                ? ""
                : `${
                    item?.currency === "USDT" ||
                    item?.currency === "GEDA" ||
                    item?.currency === "USD"
                      ? "GSTAR "
                      : `${item?.currency}`
                  } ` + formatName(item?.type)}
            </p>
          </div>
        </div>
      </td>
      <td className={`${tdStyle} text-right`}>
        <div className="flex flex-row items-center justify-end gap-space-1">
          <img
            src={getCurrencyIcon(item?.currency || "USDT")}
            alt={`${item?.currency}-icon`}
            className="w-[14px] h-[14px]"
          />
          <p className="text-l leading-[24px] blender-medium">
            {formatDollar(item?.amount)}
          </p>
        </div>
      </td>
      <td className={`${tdStyle} text-center`}>
        <p className="text-l leading-[24px] text-grey-600 blender-medium capitalize">
          {getRenderStatus(item?.status)}
        </p>
      </td>
      {!isShowLess() && (
        <td className={`${tdStyle} text-right`}>
          <p className="text-l leading-[24px] blender-medium">
            {formatDate(item?.created_at)}
          </p>
        </td>
      )}
    </tr>
  );
};
