import React, { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useWindowSize } from "@uidotdev/usehooks";
import { Token } from "src/types/currency";
import { getCurrencyIcon } from "src/utils/image";
import { ModalGSConvertForm } from "src/components/modal/modal-gs-convert-form";
import { useAuth } from "../auth-context";
import { useDashboardStore } from "src/stores";
import { openWarningNotification } from "../notification";
import { LoadingIconV2 } from "src/components/loading-icon-v2";
import { convertTokenName } from "src/utils";

const buttons = [5, 10, 25, 50, 100, 200];
const currencies = [
  Token.MAX,
  // Token.GDD, Token.GP
];

interface IModalTransferCurrency {
  setIsModalOpen: (val: boolean) => void;
  step: string;
  setStep: (val: string) => void;
}

export const ModalTransferCurrency: React.FC<IModalTransferCurrency> = ({
  setIsModalOpen,
  step,
  setStep,
}) => {
  const [amount, setAmount] = useState(5);
  const [currency, setCurrency] = useState<string>("MAX");
  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;
  const { dashboardData, getDashboardProfileCb } = useAuth();
  const { exchangeCryptoToGS } = useDashboardStore();
  const [isLoading, setIsLoading] = useState(false);

  const tokenValue = useMemo(() => {
    if (currency === "MAX") {
      return dashboardData?.max || 0;
    }
    if (currency === "GDD") {
      return dashboardData?.gdd || 0;
    }
    if (currency === "GP") {
      return dashboardData?.gp || 0;
    }
    return 0; // Fallback for unexpected currencies
  }, [currency, dashboardData]);

  const handleConvert = async () => {
    if (isLoading) return; // Prevent multiple clicks
    if (amount > tokenValue) {
      openWarningNotification(`Not enough ${currency} to convert into .`);
      return;
    }

    try {
      setIsLoading(true);
      const response = await exchangeCryptoToGS({
        currency: currency,
        amount: amount,
      });
      setIsLoading(false);
      if (!response) {
        openWarningNotification("Failed to convert currency into GSTAR.");
        return;
      }
      getDashboardProfileCb();
      setStep("complete");
    } catch (error) {
      setIsLoading(false);
      openWarningNotification("Failed to convert currency into GSTAR.");
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrencyBtnStyles = (item: string) => {
    if (!currency) return "border-grey-100";
    if (currency === item) return "border-orange-800";
    return "border-grey-800";
  };

  const getCurrencyTxtStyles = (item: string) => {
    if (!currency) return "text-grey-100";
    if (currency === item) return "text-orange-800";
    return "text-grey-800";
  };

  const renderCurrencySelection = () => {
    const getTitle = () => {
      if (!currency) return "Select token to begin";
      if (isResponsive) return convertTokenName(currency);
      return "Select token";
    };

    const getCurrencyStyles = (item: string) => {
      if (!currency) {
        if (isResponsive)
          return "w-[100px] py-space-2 px-space-3 justify-center";
        return "w-[100px] py-space-2 px-space-3 justify-start";
      }
      if (currency === item) {
        if (isResponsive) return "py-space-2 justify-center";
        return "px-space-3 py-space-4 justify-start h-fit";
      }

      if (!isResponsive) return "px-space-3 py-space-2 justify-start h-fit";
      return "w-[30px] py-space-0 px-space-0 h-[30px] justify-center";
    };
    return (
      <div className="flex flex-col gap-space-2">
        <p className="font-[700] text-xs md:text-m">{getTitle()}</p>
        {currencies.map((item, i) => (
          <div
            key={i}
            className={`flex flex-row gap-space-2 cursor-pointer bg-black-800 rounded-xs  border border-solid md:w-[230px] items-center relative overflow-hidden ${getCurrencyStyles(
              item
            )} ${getCurrencyBtnStyles(item)} transition-all duration-300`}
            onClick={() => {
              setCurrency(item);
            }}
            style={{
              boxShadow: "0px 2px 4px 0px #00000012",
            }}
          >
            <div className="flex flex-row  items-center gap-space-2 relative z-[1]">
              <img
                src={getCurrencyIcon(item)}
                alt={item}
                className="w-[20px] object-fit"
              />
              {(!currency || !isResponsive) && (
                <p
                  className={`${getCurrencyTxtStyles(item)} text-xs md:text-m`}
                >
                  {convertTokenName(item)}
                </p>
              )}
            </div>

            {/* Opacity Image as layer */}

            <img
              src={getCurrencyIcon(item, true)}
              alt={item}
              className="object-fit absolute hidden md:block"
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={
        step === "complete" ? "flex items-center justify-center py-[120px]" : ""
      }
    >
      <div className="mx-space-7">
        {/* Select Payment */}
        {step !== "complete" && (
          <div
            className={`flex flex-row gap-space-2 px-space-0 md:px-space-2 py-space-2 overflow-hidden overflow-y-auto scrollbar-hidden scrollbar-hide pb-space-6`}
          >
            {/* Select Payment Method  */}
            <div className="flex flex-col gap-space-4 py-space-2">
              {renderCurrencySelection()}
            </div>

            {/* Payment Start */}
            <ModalGSConvertForm
              amount={amount}
              setAmount={setAmount}
              amounts={buttons}
              tokenName={currency}
              step={step}
              tokenValue={tokenValue}
            />
          </div>
        )}

        {/* Complete Payment */}
        {step === "complete" && (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <div>
              <h2 className="text-grey-100 font-[500] text-l md:text-xxxxxl normal-case blender-medium">
                Conversion complete
              </h2>
            </div>
            <div>
              <p className="text-grey-800 normal-case text-s md:text-l w-[240px] md:w-[320px] font-[800] blender-medium text-center">
                The conversion request has been complete, conversion may take
                some time. You may check the status of the conversion from
                transactions history located on user dashboard
              </p>
            </div>
          </div>
        )}
      </div>
      {/* Footer */}
      <div className="absolute bottom-space-4 md:bottom-space-6 w-full bg-black-600 border-x border-grey-800">
        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />
        <div className="flex justify-center items-center py-space-2 md:py-space-4 px-space-4">
          {step !== "complete" && (
            <button
              className={`border ${
                step === "select_currency"
                  ? "border-black-100 text-black-100"
                  : "border-orange-600 text-orange-600"
              } bg-black-800 flex justify-center items-center rounded-xs blender-medium font-[500] text-s md:text-m w-[220px] md:w-[400px] h-[47px]`}
              onClick={handleConvert}
              disabled={isLoading} // Disable button when clicked
            >
              {isLoading ? <LoadingIconV2 /> : "Convert"}
            </button>
          )}
          {step === "complete" && (
            <button
              className="flex border-orange-600 bg-orange-800/30 text-grey-100 items-center justify-center rounded-xs blender-medium font-[500] text-s md:text-xl normal-case w-[220px] md:w-[400px] h-[47px]"
              onClick={() => setIsModalOpen(false)} // Close the modal
            >
              Return to dashboard
              <img
                src="/assets/v4/dashboard.png"
                alt="dashboard-icon"
                className="w-[14px] h-[14px] mx-space-1"
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
