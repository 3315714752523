import { MdOutlineLeaderboard } from "react-icons/md";
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAnglesRight } from "react-icons/fa6";

export const ClosedLeaderboardTab = () => {
  return (
    <div
      className="flex items-center justify-center w-[120px] sm:w-[146px] h-[26px] sm:h-[36px]"
      style={{
        backgroundImage: "url(/assets/v4/leaderboard-label.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}>
      <div className="flex items-center gap-space-1 ml-space-2">
        <FaAnglesLeft className="text-grey-100 w-[12px] h-[12px]" />
        <p className="blender-medium leading-[16px] text-s sm:text-l text-grey-100">
          Leaderboard
        </p>
        <MdOutlineLeaderboard className="text-orange-800 w-[14px] h-[14px] " />
      </div>
    </div>
  );
};

export const OpenedLeaderboardTab = () => {
  return (
    <div
      className="flex items-center  w-[360px] h-[32px] bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: "url(/assets/v4/leaderboard-label-long-laptop.png)",
      }}>
      <div className="flex w-full justify-between mx-space-4">
        <FaAnglesRight className="hidden sm:block  text-grey-800 w-[16px] h-[16px]" />
        <div className="flex items-center gap-space-1 ">
          <p className="blender-medium leading-[16px] text-s sm:text-l text-grey-100 sm:text-grey-800">
            Leaderboard
          </p>
          <MdOutlineLeaderboard className="text-orange-800 w-[14px] h-[14px] " />
        </div>
      </div>
    </div>
  );
};

export const OpenedLeaderboardMobileTab = () => {
  return (
    <div
      className="flex items-center justify-end w-[324px] h-[27px] ml-space-2 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: "url(/assets/v4/leaderboard-label-long.png)",
      }}>
      <div className="flex items-center gap-space-1 ml-space-2 mx-space-2">
        <FaAnglesRight className="hidden sm:block text-grey-800 w-[16px] h-[16px]" />
        <p className="blender-medium leading-[16px] text-s sm:text-l text-grey-100 sm:text-grey-800">
          Leaderboard
        </p>
        <MdOutlineLeaderboard className="text-orange-800 w-[14px] h-[14px] " />
      </div>
    </div>
  );
};
