import clsx from "clsx";
import { ICaseOpeningBoxItem } from "src/types/case-opening";
import { getCurrencyIcon, getMatrixWeaponImage } from "src/utils/image";
import { formatName } from "src/utils";
import { getRarityColor } from "src/utils/color";
import { LuInfo } from "react-icons/lu";

interface ICaseOpeningMysteryBoxItem {
  className?: string;
  item?: ICaseOpeningBoxItem | null;
  width?: number;
  type?: string | null;
  currency?: string;
}

export const CaseOpeningMysteryBoxItem = ({
  className,
  item,
  width,
  type = null,
  currency = "USDT",
}: ICaseOpeningMysteryBoxItem) => {
  const {
    name = "",
    quality = "",
    imgUrl,
    price,
    min,
    max,
    weaponPrice,
    probability,
  } = item || {};
  const weaponImageUrl = getMatrixWeaponImage(name);
  const rarityColor = getRarityColor(quality);

  const formattedPrice = min === max ? min : `${min} - ${max}`;

  return (
    <div
      className={clsx(
        className,
        "relative grid grid-rows-2 p-space-2 rounded-xs border-t-4 blender-medium",
        "case-opening-mystery-box-item-bg"
      )}
      style={{
        borderTopColor: rarityColor,
        width,
      }}
    >
      {/* header */}
      <div className="self-start flex justify-between z-[1]">
        <div className="flex items-center">
          <img
            src={getCurrencyIcon(currency)}
            className="md:w-[20px] md:h-[20px] w-[12px] h-[12px] mr-space-1"
            alt=""
          />
          <span className="text-native-green text-s md:text-l leading-none">
            {type === "recent-winnings" && weaponPrice}
            {type !== "recent-winnings" && (price || formattedPrice || "PRICE")}
          </span>
        </div>
        <LuInfo className="text-grey-100" />
      </div>
      {/* image */}
      <div className="absolute w-full h-full flex justify-center items-center overflow-hidden">
        <img
          className="case-opening-mystery-box-item-img w-full object-cover"
          src={imgUrl || weaponImageUrl}
          alt={name}
        />
      </div>
      {/* footer */}
      <div className="self-end z-[1]">
        <p className="text-s md:text-l text-grey-100 leading-none mb-space-1">
          {formatName(name)}
        </p>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div
              className="rounded-full w-[8px] h-[8px] mr-1"
              style={{ backgroundColor: rarityColor }}
            />
            <p className="text-s text-gray-200">{formatName(quality)}</p>
          </div>

          {type !== "recent-winnings" && probability && (
            <span className="text-orange-800 leading-none">
              {(Number(probability) * 100).toFixed(4)}%
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
