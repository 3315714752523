import { useEffect } from "react";
import { useLocation } from "react-router";
import { Nav } from "./nav/nav";

export const Container = (props: any) => {
  const { children, invisibleHeader, showOldNav = true } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <>
      {showOldNav && <Nav invisibleHeader={invisibleHeader} />}
      {children}
    </>
  );
};
