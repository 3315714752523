import { useWallet } from "src/hooks/useWallet";
import { ModalContainer } from "./modal-container";

export const RenderModal = () => {
  const { depositModal, setDepositModal, depositCurrency } = useWallet();

  return (
    <>
      {/* Deposit Modal */}
      {depositModal && (
        <ModalContainer
          setIsModalOpen={setDepositModal}
          type={"deposit"}
          extra={{
            depositCurrency: depositCurrency,
          }}
        />
      )}
    </>
  );
};
