interface iRoundButton {
  icon?: string;
  name?: string;
  action?: () => void;
  sx?: string;
  sxStyle?: any;
  nameSx?: string;
  iconSize?: string;
  iconSx?: string;
  iconInLeft?: boolean;
  children?: React.ReactElement;
}

export const RoundButton = (props: iRoundButton) => {
  const {
    icon,
    name = "",
    action,
    sx = "",
    sxStyle,
    nameSx = "",
    iconSize = "24px",
    iconSx = "",
    iconInLeft = false,
    children,
  } = props;

  return (
    <div
      className={`flex flex-row items-center justify-center cursor-pointer py-space-2 ${
        name ? "px-space-4" : "px-space-2"
      } bg-black-600 border-[1px] border-solid border-black-200 rounded-full gap-space-2 ${sx}`}
      style={sxStyle}
      onClick={() => {
        action && action();
      }}
    >
      {children ? (
        children
      ) : (
        <>
          {icon && iconInLeft && (
            <img
              src={icon}
              alt={name}
              className={`${iconSx ? iconSx : `w-[${iconSize}] h-[${iconSize}]`}`}
            />
          )}
          {name && <p className={`${nameSx}`}>{name}</p>}
          {icon && !iconInLeft && (
            <img
              src={icon}
              alt={name}
              className={`${iconSx ? iconSx : `w-[${iconSize}] h-[${iconSize}]`}`}
            />
          )}
        </>
      )}
    </div>
  );
};
