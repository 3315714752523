export const enTranslation = {
  message: {
    "coming-soon": "Coming Soon",
    "please-login-first": "Please login first",
  },
  nav: {
    "case-open": "Case Open",
    "watch-predict": "Watch & Predict",
    earn: "Earn",
    guild: "Guild",
    esports: "Esports",
    connect: "Connect",
    dashboard: "Dashboard",
    "top-up": "Top Up",
  },
  inbox: {
    notifications: "NOTIFICATIONS",
    platform: "Platform",
    personal: "Personal",
    "no-notifications": "No Notifications Yet",
  },
  button: {
    "mark-all-as-read": "MARK ALL AS READ",
    "show-me": "SHOW ME",
    try: "TRY NOW",
    "task-available": "Task available",
  },
  announcement: {
    won: "WON",
    with: "WITH",
    on: "ON",
    draw: "DRAW",
  },
  contact: {
    "contact-us": "CONTACT US",
  },
  footer: {
    "terms-of-service": "Terms of Service",
    "privacy-policy": "Privacy Policy",
    about: "About",
    description: "Blockchain-powered eSports infrastructure:",
    description2:
      "A revolutionary model allowing everyone to experience and engage in eSports.",
    address:
      "Regus HK, 29F, The Gateway Tower 5, Harbour City, 15 Canton Road, TST",
  },
  landing: {
    popular: "Popular",
    "watch-predict": "Watch & Predict",
    "blind-box": "Blind Box",
    "open-box": "Open Box",
    marketplace: "Marketplace",
    "spin-earn": "Spin & Earn",
    "task-available": "Task Available",
    "our-valuable-partners": "Our Valuable Partners",
    "a-special-thanks-to-all-our-supporters":
      "A special thanks to all our supporters",
    "upcoming-predictions": "Upcoming Predictions",
  },
  general: {
    "coming-soon": "Coming Soon",
  },
};
