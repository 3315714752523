import React, { useEffect, useMemo, useState } from "react";
import { useDashboardStore } from "src/stores";
import { FaCopy } from "react-icons/fa";
import { openSuccessNotification } from "./notification";
import QRCode from "react-qr-code";
import { useWindowSize } from "@uidotdev/usehooks";
import { isMobile } from "react-device-detect";

const networks = ["ETH", "TRON"];

interface IModalDepositCrypto {
  disclaimerContent: string;
}

export const ModalDepositCrypto: React.FC<IModalDepositCrypto> = (
  props: IModalDepositCrypto
) => {
  const { disclaimerContent } = props;
  const [network, setNetwork] = useState("ETH");
  const [assetCode, setAssetCode] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [minDeposit, setMinDeposit] = useState<number | null>(null);
  const [confirmation, setConfirmation] = useState<number | null>(null);

  const { createDepositAddress } = useDashboardStore();
  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  // Initialise asset code
  useEffect(() => {
    if (assetCode) return;
    setAssetCode("USDT");
  }, [network, assetCode]);

  // Get Address
  useEffect(() => {
    if (!network || !assetCode) return;
    const getAddress = async () => {
      const result = await createDepositAddress({
        network,
        assetCode,
      });
      if (!result?.wallet?.address) return;
      setAddress(result?.wallet?.address);
      setMinDeposit(result?.wallet?.minDeposit);
      setConfirmation(result?.wallet?.confirm);
    };

    getAddress();
  }, [network, assetCode, createDepositAddress]);

  const assetCodes = useMemo(() => {
    if (network === "ETH") return ["USDT", "USDC"];
    if (network === "TRON") return ["USDT"];
    return null;
  }, [network]);

  return (
    <div className="flex flex-col gap-space-4 rounded-s px-space-4 py-space-2 bg-black overflow-hidden overflow-y-auto scrollbar-hide scrollbar-hidden">
      <h2 className="text-s md:text-l blender-medium text-grey-100 normal-case mb-0">
        Please select the network and deposit to the following address
      </h2>

      {/* Network */}
      <div className="flex flex-col gap-space-1">
        <p className="text-xs">Network</p>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-space-1">
          {networks.map((val, index) => (
            <button
              key={index}
              onClick={() => setNetwork(val)}
              className={`flex h-[36px] px-[10px] py-space-3 rounded-xs items-center justify-center
            bg-grey-200/10 font-[500] text-xs md:text-s border-[1px] border-solid
            ${
              network !== val
                ? "border-black-100 text-black-100"
                : "border-orange-800 text-orange-800"
            }`}
            >
              {val}
            </button>
          ))}
        </div>
      </div>

      {/* Asset Codes */}
      <div className="flex flex-col gap-space-1">
        <p className="text-xs">Asset</p>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-space-1">
          {assetCodes &&
            assetCodes.map((val, index) => (
              <button
                key={index}
                onClick={() => setAssetCode(val)}
                className={`flex h-[36px] px-[10px] py-space-3 rounded-xs items-center justify-center
            bg-grey-200/10 font-[500] text-xs md:text-s border-[1px] border-solid
            ${
              assetCode !== val
                ? "border-black-100 text-black-100"
                : "border-orange-800 text-orange-800"
            }`}
              >
                {val}
              </button>
            ))}
        </div>
      </div>

      <div className="flex flex-col gap-space-0">
        <p className="text-m text-grey-100 font-[700]">
          Deposit via wallet address/QR
        </p>
        <p className="text-xs text-grey-600">
          Deposit can be made to the following address listed below or by
          scanning the QR
        </p>
      </div>

      {/* QR Code */}
      {address && (
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 180,
            width: "100%",
          }}
        >
          <QRCode
            size={180}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            fgColor="#fff"
            bgColor="#000"
            value={address}
          />
        </div>
      )}

      {address && (
        <div className="relative">
          <input
            type="text"
            value={isResponsive ? `${address.slice(0, 20)}...` : address}
            disabled
            className="py-space-4 w-full h-[40px] text-left placeholder-left pl-space-4 border-black-200 rounded-xs border-solid border-[1px] placeholder-black-200 text-grey-800 placeholder-blender-medium placeholder-font-[700] placeholder-text-l font-[400] text-s blender-medium bg-transparent placeholder-center placeholder-text-grey-600 focus:border-orange-800 focus:outline-none pr-space-10 overflow-hidden"
          />
          <FaCopy
            className="w-[14px] h-[14px] md:w-[18px] md:h-[18px] absolute right-space-2  top-[12px] md:top-[10px] cursor-pointer"
            color="#737373"
            onClick={() => {
              navigator.clipboard.writeText(address);
              openSuccessNotification("You have copied the address");
            }}
          />
        </div>
      )}

      <div className="flex flex-col gap-space-2">
        <p className="text-m text-grey-100 font-[700]">Details</p>

        {/* Notes */}
        {minDeposit && (
          <div className="flex flex-row justify-between">
            <p className="text-xs">Minimum Deposit</p>
            <p className="text-xs text-orange-600">
              {minDeposit} {assetCode}
            </p>
          </div>
        )}

        {/* Confirmation */}
        {confirmation && (
          <div className="flex flex-row justify-between">
            <p className="text-xs">Confirmation</p>
            <p className="text-xs text-orange-600">{confirmation} Blocks</p>
          </div>
        )}

        {/* Exchange Rate */}
        {assetCode && (
          <div className="flex flex-row justify-between">
            <p className="text-xs">Exchange Rate</p>
            <p className="text-xs text-orange-600">1 {assetCode} : 1 GSTAR</p>
          </div>
        )}
      </div>

      {/* Disclaimer */}
      <div className="flex flex-col gap-space-1">
        <p className="blender-medium font-[500] text-native-red text-s">
          *Disclaimer*
        </p>
        <p className="blender-medium font-[400] text-s text-grey-600 whitespace-pre-line leading-[1.3]">
          {disclaimerContent}
        </p>
      </div>
    </div>
  );
};
