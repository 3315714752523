import { LandingPartnersHorizontalConveyer } from "./landing-partners-horizontal-conveyer";
import { LandingPartnersVerticalConveyer } from "./landing-partners-vertical-conveyer";
import { iPartnerRow } from "src/types/landing";
import { partnerRows } from "src/config/landing";
import { t } from "i18next";

interface iLandingPartners {}

export const LandingPartners = (props: iLandingPartners) => {
  return (
    <div
      className="relative mx-0 md:mx-space-2 mb-space-4 h-full p-space-2 md:px-space-6 md:py-0 rounded-s overflow-hidden"
      style={{
        background: "#242424",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%)",
        boxShadow: "0px 4px 4px 0px #00000040 inset",
      }}
    >
      <div className="hidden sm:grid grid-cols-3 gap-space-4 md:w-[64.6%] md:px-space-2 md:py-space-2">
        {partnerRows &&
          partnerRows.map((partnerRow: iPartnerRow, i: number) => {
            return (
              <LandingPartnersVerticalConveyer
                partners={partnerRow}
                isReversedScroll={i % 2 === 0}
                key={i}
              />
            );
          })}
      </div>
      <div className="flex sm:hidden flex-col gap-space-2 md:px-space-2 md:py-space-2">
        {partnerRows &&
          partnerRows.map((partnerRow: iPartnerRow, i: number) => {
            return (
              <LandingPartnersHorizontalConveyer
                partners={partnerRow}
                isReversedScroll={i % 2 === 0}
                key={i}
              />
            );
          })}
      </div>
      <div className="w-[77%] md:w-[31.8%] absolute left-[50%] md:left-[initial] md:right-[24px] top-[50%] md:transform translate-x-[-50%] md:translate-x-[0%] translate-y-[-50%] text-center md:text-right">
        <p className="text-[24px] sm:text-[48px] leading-[24px] sm:leading-[45px] acpex mb-space-2 md:mb-space-6">
          {t("landing.our-valuable-partners")}
        </p>
        <p className="text-[12px] sm:text-[24px] leading-[14px] sm:leading-[28px] blender-medium">
          {t("landing.a-special-thanks-to-all-our-supporters")}
        </p>
      </div>
    </div>
  );
};
