import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { LuckyDrawGame } from "./pages/lucky-draw-game";
import { MyBag } from "./pages/my-bag";
import { RaffleV2 } from "./pages/raffle-v2";
import { AboutUs } from "./pages/about-us";

// V3
// import { PredictionV3 } from "./pages/prediction-v3";
// import { HomeV3 } from "./pages/index-v3";
// import { Airdrop } from "./pages/airdrop";
// import { PredictionV3Detail } from "./pages/prediction-v3-detail";

// V4
import { AirdropV2 } from "./pages/airdrop-v2";
import { HomeV4 } from "./pages/index-v4";
import { MarketplaceMysteryBoxList } from "./pages/marketplace-mystery-box-list";
import { CaseOpeningMysteryBox } from "./pages/case-opening-mystery-box";
import { PredictionV4 } from "./pages/prediction-v4";
import { PredictionV4Detail } from "./pages/prediction-v4-detail";
import { DashboardV2 } from "./pages/dashboard-v2";
import { Privacy } from "./pages/privacy";
import { Tos } from "./pages/tos";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/"} exact component={HomeV4} />
        {/* Lucky Draw */}
        <Route path={"/raffle"} exact component={RaffleV2} />
        <Route path={"/raffle/:id"} exact component={LuckyDrawGame} />
        {/* Prediction */}
        <Route path={"/prediction"} exact component={PredictionV4} />
        <Route path={"/prediction/:id"} exact component={PredictionV4Detail} />
        {/* My Bag */}
        <Route path={"/my-bag"} exact component={MyBag} />
        {/* Airdrop */}
        <Route path={"/airdrop"} exact component={AirdropV2} />
        {/* About Us */}
        <Route path={"/about-us"} exact component={AboutUs} />
        {/* v4 */}
        <Route path={"/dashboard"} exact component={DashboardV2} />
        <Route
          path={"/mystery-box"}
          exact
          component={MarketplaceMysteryBoxList}
        />
        <Route
          path={"/mystery-box/:id"}
          exact
          component={CaseOpeningMysteryBox}
        />
        <Route path={"/tos"} exact component={Tos} />
        <Route path={"/privacy"} exact component={Privacy} />

        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
