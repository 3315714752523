import { useRef, useState } from "react";
import { useSpring, animated } from "react-spring/web";
import { useWindowSize } from "@uidotdev/usehooks";
import { hotGames } from "src/config/landing";
import { HotGame } from "./hot-game";
import { IGameList } from "src/types/prediction";
import { t } from "i18next";

interface iLandingHotGames {}

export const LandingHotGames = (props: iLandingHotGames) => {
  const size: any = useWindowSize();

  const mainRef = useRef<HTMLDivElement | null>(null);

  const [animationOn, setAnimationOn] = useState<boolean>(false);

  let mainScrollWidth = 0; //should not access direcly
  const getMainScrollWidth = () => {
    mainScrollWidth =
      mainRef && mainRef.current ? mainRef.current.scrollWidth : 0;
    return mainScrollWidth;
  };

  const isChangedMainScrollWidth = () => {
    return (
      mainScrollWidth !==
      (mainRef && mainRef.current ? mainRef.current.scrollWidth : 0)
    );
  };

  const scrollGameCount = () => {
    if (size?.width < 760) {
      return Math.min(hotGames ? hotGames.length : 0, 1);
    }

    return Math.min(hotGames ? hotGames.length : 0, 3);
  };

  const isScrollGame = () => {
    return hotGames && hotGames.length > scrollGameCount();
  };

  const scrollSpring = useSpring({
    to: {
      x: getMainScrollWidth() / 2,
    },
    from: {
      x: 1,
    },
    onFrame: (props: any) => {
      if (mainRef && mainRef.current) {
        if (
          props.x <= 1 ||
          isChangedMainScrollWidth() ||
          props.x >= getMainScrollWidth() / 2
        ) {
          setAnimationOn(true);

          setTimeout(() => {
            setAnimationOn(false);
          }, 10);
        }

        mainRef.current.scrollLeft = props.x;
      } else {
        setAnimationOn(true);

        setTimeout(() => {
          setAnimationOn(false);
        }, 10);
      }
    },
    config: {
      duration: 5000 * (hotGames ? hotGames.length : 0),
    },
    reset: animationOn,
  });

  return (
    <div
      className="h-full flex items-start items-stretch flex-col mx-0 md:mx-space-2 mb-space-4 p-space-2 md:p-space-6 rounded-s"
      style={{
        background: "linear-gradient(360deg, #111111 0%, #262626 100%)",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%)",
        boxShadow: "0px 4px 4px 0px #00000040 inset",
      }}
    >
      <p className="text-l md:text-xxml font-[500] leading-[19px] md:leading-[28px] blender-medium mb-space-2 md:mb-space-4">
        {t("landing.popular")}
      </p>
      <animated.div
        className="flex-[1] overflow-hidden"
        style={{
          ...(isScrollGame() && scrollSpring),
        }}
        ref={mainRef}
      >
        <div
          className="flex flex-row gap-space-4"
          style={{
            width: isScrollGame()
              ? (hotGames.length / scrollGameCount()) * 200 + "%"
              : "100%",
          }}
        >
          {hotGames &&
            hotGames.map((item: IGameList, i: number) => {
              return (
                <div className="flex-1" key={i}>
                  <HotGame item={item} isExpand={true} />
                </div>
              );
            })}

          {isScrollGame() &&
            hotGames &&
            hotGames.map((item: IGameList, i: number) => {
              return (
                <div className="flex-1" key={i}>
                  <HotGame item={item} isExpand={true} />
                </div>
              );
            })}
        </div>
      </animated.div>
    </div>
  );
};
