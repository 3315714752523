export enum InboxFolder {
  PLATFORM = "platform",
  PERSONAL = "personal",
}

export interface TabItem {
  folder: InboxFolder;
  unread: number;
}

export interface IMessage {
  id: string;
  dateTime: string;

  // optional
  type?: string;
  subject?: string;
  summary?: string;
  content?: string;
  imageUrl?: string;
  sitePath?: string;
  read?: boolean;
}

export interface Inbox extends TabItem {
  messages: IMessage[];
}

export const InboxFolderOrder = {
  [InboxFolder.PLATFORM]: 0,
  [InboxFolder.PERSONAL]: 1,
} as const;
