import clsx from "clsx";
import { IoCloseOutline } from "react-icons/io5";

interface ICaseOpeningModal {
  className?: string;
  footerClassName?: string;
  isVisible: boolean;
  tag?: React.ReactNode;
  title?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  bonus?: number;
  onClose?: () => void;
}

const CaseOpeningModal = ({
  className,
  footerClassName,
  isVisible,
  tag,
  title,
  content,
  footer,
  bonus,
  onClose,
}: ICaseOpeningModal) => {
  const hasBonus = typeof bonus === "number" && bonus > 0;

  return (
    <div
      className={clsx(
        { hidden: !isVisible },
        "absolute top-0 left-0 w-full h-full flex justify-center items-center z-10"
      )}
    >
      <div className="relative flex items-center justify-center">
        {/* bonus */}
        {hasBonus && (
          <div
            className={clsx(
              "absolute flex bg-cover",
              "bg-[url('/assets/v4/bonus-section-mobile-bg.png')] w-[calc(100%_-_16px)] h-[100px] -top-[38px] p-space-1 animate-fade-up",
              "md:bg-[url('/assets/v4/bouns-section-bg.png')] md:items-center md:justify-end md:-right-[160px] md:top-auto md:w-[295px] md:h-[399px] md:p-space-3 md:animate-fade-right",
              "animate-once animate-delay-800 animate-ease-out"
            )}
          >
            <div
              className={clsx(
                "grid blender-medium",
                "w-full h-[36px] grid-cols-2 grid-rows-2",
                "md:w-[160px] md:h-[248px] md:grid-flow-row md:grid-rows-[30px_1fr_30px] md:grid-cols-none"
              )}
            >
              <h4 className="self-center justify-self-end mb-0 md:justify-self-center text-grey-100 text-s md:text-xxml leading-none">
                Bonus
              </h4>
              <div className="w-[60px] md:w-auto self-center flex items-center md:justify-around">
                <img
                  className={clsx(
                    "w-[14px] h-[14px] mr-space-4 md:w-[36px] md:h-[36px] md:-mr-space-2",
                    "animate-wiggle-more md:animate-wiggle animate-infinite animate-duration-[1500ms]  animate-ease-in-out"
                  )}
                  src="/assets/v4/gdd.png"
                  alt=""
                />
                <span className="text-orange-800 text-s md:text-xxml mr-space-1 md:mr-0">
                  ×
                </span>
                <span className="text-orange-800 text-s md:text-xxml">
                  {bonus}
                </span>
              </div>
              <p className="col-span-2 place-self-center md:col-auto md:self-end md:place-self-auto text-grey-600 text-xs leading-tight">
                *GDD will remain after item is sold
              </p>
            </div>
          </div>
        )}
        {/* main */}
        <div
          className={clsx(
            className,
            "relative md:w-[640px] w-[277px] md:h-[590px] h-[480px]",
            "md:bg-[url('/assets/v4/case-opening-frame-bg-black.png')] bg-[url('/assets/v4/modal-mobile-bg.png')] bg-cover"
          )}
        >
          {/* header */}
          <div className="mt-space-4 md:mt-space-6 md:px-space-9 px-space-6 md:py-space-4 py-space-2 flex items-center justify-between border-b-2 border-black-400 blender-medium">
            <div>
              {tag}
              <p className="text-l md:text-xxxxxl text-grey-100 leading-none">
                {title}
              </p>
            </div>
            <IoCloseOutline
              className="w-[28px] h-[28px] md:w-[40px] md:h-[40px] cursor-pointer bg-neutral-500/20 text-orange-800 rounded-full"
              onClick={onClose}
            />
          </div>
          {/* content */}
          {content}
          {/* footer */}
          <div
            className={clsx(
              footerClassName,
              "md:border-t-2 md:border-black-400 blender-medium"
            )}
          >
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CaseOpeningModal };
