import clsx from "clsx";

export interface ICaseOpeningBoxCard {
  className?: string;
  name: string;
  price?: number;
  imgUrls: string[];
  isNew?: boolean;
  isSelectable: boolean;
  onClick?: () => void;
  currency: string;
  finalPrice?: number;
}

const CaseOpeningBoxCard = ({
  className,
  name,
  price,
  imgUrls,
  isNew,
  isSelectable,
  onClick,
  currency,
  finalPrice,
}: ICaseOpeningBoxCard) => {
  const haveMultipleImg = imgUrls.length > 1;
  return (
    <div
      className={clsx(
        className,
        "relative aspect-square grid grid-rows-2 blender-medium p-space-2 md:backdrop-blur-[80px] rounded-s shadow-box",
        { "cursor-pointer": isSelectable, "cursor-not-allowed": !isSelectable },
        { "case-opening-case": haveMultipleImg }
      )}
      onClick={onClick}
    >
      {/* header */}
      <div className="flex justify-between z-[1]">
        <div className="text-s md:text-l text-grey-200 font-[900] break-word">
          {name}
        </div>
        <div className="flex flex-row gap-space-2">
          {price && (
            <div className="text-s md:text-l text-orange-800 font-[900]">{`$${price}`}</div>
          )}
        </div>
      </div>
      {/* image */}
      <div className="absolute w-full h-full top-0 left-0 z-[0]">
        <img
          className="w-full aspect-square object-contain object-center"
          src="/assets/v4/overlay-faded.png"
          alt={name}
        />
        {imgUrls.map((imgUrl, i) => (
          <img
            key={i}
            className={clsx(
              { [`case-opening-case-img-${i}`]: haveMultipleImg },
              "w-full aspect-square absolute top-0 left-0 object-contain object-center"
            )}
            src={imgUrl}
            alt={name}
          />
        ))}
      </div>
      {/* tag */}
      {isNew && (
        <div
          className={clsx(
            "w-full row-span-2 self-end py-space-0 rounded-xs border md:backdrop-blur-[10px] z-[1]",
            "border-orange-800 bg-orange-800/10",
            "text-center text-white text-xs"
          )}
        >
          New
        </div>
      )}
      {/* Overlay */}
      {!isSelectable && (
        <img
          src="/assets/v4/overlay.png"
          alt="overlay"
          className="w-full h-full absolute top-0 left-0 opacity-50"
        />
      )}
    </div>
  );
};

export { CaseOpeningBoxCard };
