import { useMemo, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { openSuccessNotification } from "src/components";
import { IDashboardProfile } from "src/types/dashboard";
import { formatLastUpdateDateTIme } from "src/utils";
import { BsFillBackpackFill } from "react-icons/bs";
import { CURRENCIES } from "src/config/currencies";
import {
  FiChevronDown,
  FiChevronUp,
  FiCopy,
  FiDownload,
  FiUpload,
} from "react-icons/fi";
import { SlWallet } from "react-icons/sl";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  DISPLAY_MODE_DEFAULT,
  DISPLAY_MODE_CASE,
  DISPLAY_MODE_SPIN,
} from "src/config/dashboard";
import { useAuth } from "./auth-context";

interface iDashboardProfile {
  data: IDashboardProfile;
  dataReceivedDate: Date | null;
  dashboardDisplayMode: string;
  setDashboardDisplayMode: (val: string) => void;
  setDepositModal: (val: boolean) => void;
  setTransferModal: (val: boolean) => void;
  setTransferCurrencyModal: (val: boolean) => void;
}

export const DashboardProfile = (props: iDashboardProfile) => {
  const {
    data,
    dataReceivedDate,
    dashboardDisplayMode,
    setDashboardDisplayMode,
    setDepositModal,
    setTransferModal,
    setTransferCurrencyModal,
  } = props;
  const btnStyle = `w-full border-[1px] border-solid p-space-2 rounded-xs cursor-pointer mx-auto flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px]`;
  const greyHoverStyle = `hover:drop-shadow-[0_4px_4px_rgba(115,115,115,0.5)] transition drop-shadow duration-700`;
  const greenHoverStyle = `hover:drop-shadow-[0_4px_4px_rgba(30,187,113,0.5)] transition drop-shadow duration-700`;
  const size: any = useWindowSize();
  const { userInfo, setOpenConnectAddressDialog } = useAuth();

  const [isExpand, setIsExpand] = useState(true);
  const [isShowMoreDetails, setIsShowMoreDetails] = useState(false);

  const shortenedAddress = useMemo(() => {
    if (!userInfo?.address) return "";
    return userInfo?.address.slice(0, 6) + "..." + userInfo?.address.slice(-4);
  }, [userInfo]);

  const copyGUID = () => {
    if (!userInfo) return;
    navigator.clipboard.writeText(userInfo?.guid);
    openSuccessNotification("You have copied your GUID.");
  };

  return (
    <div className="relative bg-[#19191966] p-space-2 sm:p-space-4 rounded-s backdrop-blur-[8px]">
      {/* Avatar, desktop */}
      <div className="hidden sm:block">
        <div className="grid gap-space-6 justify-center items-center">
          <img
            src={`/assets/v4/avatar.png`}
            alt={`avatar`}
            className="w-[120px] h-[120px] mx-auto"
          />
          <div className="grid gap-space-2 text-center">
            <h1 className="text-[24px] leading-[22px] text-grey-100 acpex mb-0">
              {data?.username}
            </h1>

            <div className="flex flex-row items-center justify-center gap-space-1">
              <p className="text-m leading-[16px] font-[500] blender-medium text-orange-800 cursor-pointer">
                <span className="text-grey-800">GUID: </span>
                {userInfo?.guid}
              </p>

              <FiCopy
                className="w-[16px] h-[16px] text-grey-800 cursor-pointer"
                onClick={() => copyGUID()}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Avatar, mobile */}
      <div
        className={`${
          dashboardDisplayMode === DISPLAY_MODE_DEFAULT ? "block" : "hidden"
        } sm:hidden`}
      >
        <div className="flex flex-row items-center gap-space-2">
          <img
            src={`/assets/v4/avatar.png`}
            alt={`avatar`}
            className="w-[48px] h-[48px]"
          />
          <div className="flex-[1]">
            <h1 className="text-l leading-[16px] text-grey-100 acpex mb-space-1">
              {data?.username}
            </h1>

            <div className="flex flex-row gap-space-1">
              <p className="text-m leading-[16px] font-[500] blender-medium text-orange-800 cursor-pointer">
                <span className="text-grey-800">GUID: </span>
                {userInfo?.guid}
              </p>

              <FiCopy
                className="w-[16px] h-[16px] text-grey-800 cursor-pointer"
                onClick={() => copyGUID()}
              />
            </div>
          </div>
          <div
            className="flex flex-row items-center gap-space-1 cursor-pointer"
            onClick={() => setIsExpand(!isExpand)}
          >
            <p
              className={`text-s leading-[14px] font-[500] blender-medium ${
                isExpand ? "text-grey-800" : "text-grey-100"
              }`}
            >
              {isExpand ? "Hide all" : "Show all"}
            </p>
            {isExpand && (
              <FiChevronUp className="w-[14px] h-[14px] text-grey-800" />
            )}
            {!isExpand && (
              <FiChevronDown className="w-[14px] h-[14px] text-grey-100" />
            )}
          </div>
        </div>
      </div>

      <div>
        {/* Back to Dashboard Button */}
        <div
          className={`${
            dashboardDisplayMode === DISPLAY_MODE_DEFAULT ? "hidden" : "block"
          } ${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800 mt-0 sm:mt-space-4`}
          onClick={() => setDashboardDisplayMode(DISPLAY_MODE_DEFAULT)}
        >
          <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
            Back to Dashboard
          </p>
          <RiArrowGoBackLine className="w-[10px] h-[10px] text-grey-100" />
        </div>

        <div
          className={`grid ${
            size?.width >= 760 || dashboardDisplayMode === DISPLAY_MODE_DEFAULT
              ? "grid-rows-[1fr] mt-space-4 sm:mt-space-6"
              : "grid-rows-[0fr]"
          } transition-all duration-500`}
        >
          <div className="overflow-hidden">
            <div
              className={`grid ${
                size?.width >= 760 || isExpand
                  ? "grid-rows-[1fr] mb-space-4"
                  : "grid-rows-[0fr]"
              } transition-all duration-500`}
            >
              {/* Balance Details */}
              <div className="grid gap-space-4 overflow-hidden">
                <div>
                  <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                    Balance
                  </p>
                  {dataReceivedDate && (
                    <p className="text-s leading-[14px] text-grey-800 blender-medium mt-space-1">
                      Last updated {formatLastUpdateDateTIme(dataReceivedDate)}
                    </p>
                  )}
                </div>
                <div className="grid grid-cols-4 gap-space-1">
                  {CURRENCIES.map((currency, index) => (
                    <div
                      key={index}
                      className="flex flex-col gap-space-1 sm:gap-space-2"
                    >
                      <div className="flex flex-col items-center gap-space-1">
                        <img
                          src={currency.icon}
                          alt={currency.initial}
                          className="w-[20px] h-[20px]"
                        />
                        <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-600">
                          {currency.name}
                        </p>
                      </div>
                      <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100 break-words">
                        {data?.[currency.value as keyof typeof data] || 0}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Deposit Button */}
            <div
              className={`${btnStyle} ${greenHoverStyle} bg-[#1EBB714D] border-native-green`}
              onClick={() => setDepositModal(true)}
            >
              <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-native-green">
                Top up
              </p>
              <SlWallet className="w-[10px] h-[10px] text-native-green" />
            </div>
          </div>
        </div>
        <div
          className={`grid ${
            size?.width >= 760 ||
            (dashboardDisplayMode === DISPLAY_MODE_DEFAULT && isExpand)
              ? "grid-rows-[1fr] mt-space-4"
              : "grid-rows-[0fr]"
          } transition-all duration-500`}
        >
          <div className="grid grid-cols-2 gap-space-4 overflow-hidden">
            {/* convert to GS button */}
            <div
              className={`${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800`}
              onClick={() => {
                setTransferCurrencyModal(true);
              }}
            >
              <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                Convert to GS
              </p>
              <FiDownload className="w-[10px] h-[10px] text-grey-100" />
            </div>

            {/* Trasnfer button */}
            <div
              className={`${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800`}
              onClick={() => setTransferModal(true)}
            >
              <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                Transfer
              </p>
              <FiUpload className="w-[10px] h-[10px] text-grey-100" />
            </div>
          </div>
        </div>
        <div
          className={`grid ${
            dashboardDisplayMode === DISPLAY_MODE_DEFAULT
              ? "grid-rows-[1fr] mt-space-4 sm:mt-space-6"
              : "grid-rows-[0fr]"
          } transition-all duration-500`}
        >
          <div className="overflow-hidden">
            <div
              className={`grid ${
                size?.width >= 760 || isExpand
                  ? "grid-rows-[1fr] mb-space-4"
                  : "grid-rows-[0fr]"
              } transition-all duration-500`}
            >
              {/* Inventory Details */}
              <div className="grid gap-space-4 overflow-hidden">
                <div>
                  <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                    Inventory
                  </p>
                  {dataReceivedDate && (
                    <p className="text-s leading-[14px] text-grey-800 blender-medium mt-space-1">
                      Last updated {formatLastUpdateDateTIme(dataReceivedDate)}
                    </p>
                  )}
                </div>
                <div className="grid grid-cols-3 gap-space-1">
                  <div className="flex flex-col gap-space-2">
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-800">
                      New items
                      <span className="inline-block w-[8px] h-[8px] bg-orange-800 ml-space-1 rounded-full"></span>
                    </p>
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100">
                      Coming soon!{/* TODO: Mock Data */}
                    </p>
                  </div>
                  <div className="flex flex-col gap-space-2">
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-800">
                      Spin & Earn
                    </p>
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100">
                      Coming soon!{/* TODO: Mock Data */}
                    </p>
                  </div>
                  <div className="flex flex-col gap-space-2">
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-800">
                      Open Box
                    </p>
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100">
                      Coming soon!{/* TODO: Mock Data */}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* View inventory button */}
            <div
              className={`${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800`}
              onClick={() => setDashboardDisplayMode(DISPLAY_MODE_SPIN)}
            >
              <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                View inventory
              </p>
              <BsFillBackpackFill className="w-[10px] h-[10px] text-grey-100" />
            </div>
          </div>
        </div>
        <div
          className={`grid ${
            dashboardDisplayMode === DISPLAY_MODE_DEFAULT
              ? "grid-rows-[1fr] mt-space-4 sm:mt-space-6"
              : "grid-rows-[0fr] mt-0 sm:mt-0"
          } transition-all duration-500`}
        >
          <div className="overflow-hidden">
            {/* More or Hide details button */}
            <div
              className={`block sm:hidden ${btnStyle} bg-[#242424CC] border-grey-800`}
              onClick={() => setIsShowMoreDetails(!isShowMoreDetails)}
            >
              <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                {isShowMoreDetails ? "Hide details" : "More details"}
              </p>
              {isShowMoreDetails && (
                <FiChevronUp className="w-[14px] h-[14px] text-grey-100" />
              )}
              {!isShowMoreDetails && (
                <FiChevronDown className="w-[14px] h-[14px] text-grey-100" />
              )}
            </div>

            <div
              className={`grid ${
                size?.width >= 760 || isShowMoreDetails
                  ? "grid-rows-[1fr] mt-space-4 sm:mt-space-2"
                  : "grid-rows-[0fr]"
              } transition-all duration-500`}
            >
              <div className="grid gap-space-4 sm:gap-space-6 overflow-hidden">
                {/* Geda Details */}
                <div className="grid gap-space-4">
                  <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                    GeDa stats
                  </p>
                  <div className="grid gap-space-2">
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium uppercase">
                        Total score (season 1):
                      </p>
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                        {data?.totalGeDa}
                      </p>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                        Energy
                      </p>
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                        {data?.energy}%
                      </p>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                        Multiplier
                      </p>
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                        {data?.multiplier}%
                      </p>
                    </div>
                  </div>
                </div>
                {/* Account Details */}
                <div className="grid gap-space-4">
                  <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                    Account
                  </p>
                  <div className="grid gap-space-2">
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                        Joined
                      </p>
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                        {data?.joined}
                      </p>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                        Last successful sign-in
                      </p>
                      <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                        Coming soon!{/* TODO: API integration required */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`grid ${
            dashboardDisplayMode === DISPLAY_MODE_DEFAULT
              ? "grid-rows-[1fr] mt-space-4 sm:mt-space-6"
              : "grid-rows-[0fr]"
          } transition-all duration-500`}
        >
          <div className="grid gap-space-4 sm:gap-space-6 overflow-hidden">
            {/* Settings button */}
            <div
              className={`${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800`}
              onClick={() => {
                if (userInfo?.address) return;
                setOpenConnectAddressDialog(true);
              }}
            >
              <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                {userInfo?.address
                  ? `Wallet Connected to ${shortenedAddress}`
                  : "Connect Wallet"}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`grid ${
            dashboardDisplayMode === DISPLAY_MODE_SPIN ||
            dashboardDisplayMode === DISPLAY_MODE_CASE
              ? "grid-rows-[1fr] mt-space-4"
              : "grid-rows-[0fr]"
          } transition-all duration-500`}
        >
          <div className="grid gap-space-4 sm:gap-space-6 overflow-hidden">
            {/* Inventory Details */}
            <div className="grid gap-space-4">
              <div>
                <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                  Inventory
                </p>
                {dataReceivedDate && (
                  <p className="text-s leading-[14px] text-grey-800 blender-medium mt-space-1">
                    Last updated {formatLastUpdateDateTIme(dataReceivedDate)}
                  </p>
                )}
              </div>
              <div className="grid gap-space-2">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                    Total items count
                  </p>
                  <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                    Coming soon!{/* TODO: API integration required */}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-between">
                  <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                    Total sell value
                  </p>
                  <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                    Coming soon!{/* TODO: API integration required */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
