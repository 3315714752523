import { Currency } from "src/types/currency";
import { IMysteryBox, IMysteryBoxPrizeDetail } from "src/types/mysteryBox";

type gedaCaseGifMapType = { [key: string]: string };

const gedaCaseGifMap: gedaCaseGifMapType = {
  "Pistol Case": "/assets/case/Pistol_Case.gif",
  "Rifle Case": "/assets/case/Rifle_Case.gif",
};

const getBoxPrice = (box: IMysteryBox) => {
  const { currency, price = 0, final_price = 0 } = box || {};
  if (!currency) {
    return price;
  }

  return currency === Currency.USDT ? price : final_price
};

const calculateWeaponPrice = (prize: IMysteryBoxPrizeDetail) => {
  const { sellCurrency, weaponPrice = 0, weaponConvertPrice = 0 } = prize || {};
  if (sellCurrency && sellCurrency !== "USDT") {
    return weaponConvertPrice;
  }

  return weaponPrice;
};

const isValuablePrize = (prize: IMysteryBoxPrizeDetail, box: IMysteryBox) => {
  if (!prize) return false;

  const boxPrice = getBoxPrice(box);
  const weaponPrice = calculateWeaponPrice(prize);

  return weaponPrice > boxPrice * 10;
}



export { gedaCaseGifMap, getBoxPrice, calculateWeaponPrice, isValuablePrize };