export const capitalised = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const padZerosBehind = (amount: any, dp: number) => {
  return String(amount * 10 ** dp);
};

export function getQueryParam(queryString?: string) {
  const urlSearchParams = new URLSearchParams(queryString);
  const queryObj = Object.fromEntries(urlSearchParams.entries());
  return queryObj;
}

export const formatDollar = (amount: any, withDollarSign?: false) => {
  amount = parseFloat(amount);
  const formatter = new Intl.NumberFormat(
    "en-US",
    withDollarSign
      ? {
          style: "currency",
          currency: "USD",
        }
      : {}
  );
  return formatter.format(amount);
};

export const formatLastUpdateDateTIme = (date: Date) => {
  const year = String(date.getFullYear()).substr(2, 2);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} @${hours}:${minutes}:${seconds}`;
};

export const formatName = (name: string) => {
  return name
    .split("_") // Split the string into words based on underscores
    .map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of each word
    )
    .join(" "); // Join the words back together with spaces
};

export const formatPrice = (price: string | number | null) => {
  if (price === null) return "";
  const num = Number(price);

  if (isNaN(num)) return "Not number";

  return num.toFixed(2);
};

export const formatMessage = (msg: string) => {
  return msg.charAt(0).toUpperCase() + msg.slice(1).replaceAll("_", " ") + ".";
};

export const formatTextToUpperCase = (text: string) => {
  return text.toUpperCase().replaceAll("_", " ");
};

export const formatUnread = (unread: number) => {
  return unread > 99 ? "99+" : `${unread}`;
};

export const convertTokenName = (tokenName: string) => {
  if (tokenName === "MAX") return "GMAX";
  if (tokenName === "GEDA" || !tokenName || tokenName === "USDT")
    return "GSTAR";
  return tokenName;
};
