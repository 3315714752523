import { FC, useState, useContext } from "react";
import clsx from "clsx";
import { useSpring, animated } from "@react-spring/web";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { InboxIcon } from "./inbox-icon";
import { InboxContext } from "./inbox-provider";
import { formatUnread } from "src/utils";
import { InboxFolder, InboxFolderOrder } from "src/types/inbox";
import { InboxMessageContainer } from "./inbox-message-container";
import { InboxModal } from "./inbox-modal";
import { t } from "i18next";

interface ISwitchTabProps {
  className?: String;
  active: InboxFolder;
  setActive: React.Dispatch<React.SetStateAction<InboxFolder>>;
}

const SwitchTab: FC<ISwitchTabProps> = ({ className, active, setActive }) => {
  const { inboxes } = useContext(InboxContext);
  const [springs] = useSpring(
    () => ({
      to: {
        transform: `translateX(${100 * InboxFolderOrder[active]}%)`,
      },
    }),
    [active]
  );

  return (
    <div
      className={clsx(
        className,
        "relative flex bg-black-400 items-center mx-space-2"
      )}
    >
      <animated.div
        className="absolute h-full bg-black-200 rounded-xs"
        style={{
          width: `calc(100% / ${inboxes.length})`,
          ...springs,
        }}
      />
      {inboxes.map(({ folder, unread }) => {
        const isActive = active === folder;
        const hasUnread = unread > 0;
        const count = formatUnread(unread);

        return (
          <div
            key={folder}
            className="relative flex items-center justify-center flex-grow cursor-pointer"
            onClick={() => setActive(folder)}
          >
            <h4
              className={clsx(
                "relative inline-flex items-center m-0 text-ml capitalize leading-none",
                {
                  "text-grey-800": !isActive,
                  "text-grey-100": isActive,
                }
              )}
            >
              {t(`inbox.${folder}`)}
              {hasUnread && (
                <span className="w-[24px] h-[24px] absolute left-full translate-x-space-2 flex items-center justify-center rounded-full bg-[#EE484E] text-white text-m leading-none">
                  {count}
                </span>
              )}
            </h4>
          </div>
        );
      })}
    </div>
  );
};

interface IInboxProps {
  className?: String;
}

const Inbox: FC<IInboxProps> = ({ className }) => {
  const [activeFolder, setActiveFolder] = useState<InboxFolder>(
    InboxFolder.PLATFORM
  );
  const { isInboxOpen, unread, setIsInboxOpen, readAllMessage } =
    useContext(InboxContext);
  const hasUnred = unread > 0;

  const [springs] = useSpring(
    () => ({
      to: {
        transform: `translateX(${isInboxOpen ? 0 : 100}%)`,
      },
    }),
    [isInboxOpen]
  );

  const handleOpen = () => setIsInboxOpen(!isInboxOpen);

  const handleReadAll = () => {
    readAllMessage(activeFolder);
  };

  return (
    <>
      <animated.div
        className={clsx(
          className,
          "w-full",
          "md:max-w-[375px] grid grid-flow-row grid-rows-[36px_40px_1fr_50px] gap-space-2",
          "blender-medium bg-[#2D2D2D]"
        )}
        style={springs}
      >
        <div
          className="relative h-full flex items-center justify-center bg-black-200 cursor-pointer"
          onClick={handleOpen}
        >
          <div className="md:hidden flex items-center absolute left-0 w-[56px] h-[28px] text-grey-100 rounded-r-full bg-black-100">
            <AiOutlineDoubleRight className="relative left-[28px] w-[24px] h-[24px] animate-fade-right animate-infinite animate-duration-[2000ms] animate-ease-in-out" />
          </div>
          <div
            className={clsx(
              "hidden md:flex items-center absolute -left-[46px] w-[46px] h-[36px] rounded-l-xs",
              {
                "bg-black-200": isInboxOpen,
                "bg-black-200/70": !isInboxOpen,
                "opacity-50 hover:opacity-100": !isInboxOpen && !hasUnred,
              }
            )}
          >
            <div
              className={clsx(
                "w-[2px] h-[20px] ml-space-2 mr-space-0 rounded-xs",
                {
                  "bg-native-red": hasUnred,
                  "bg-grey-100": !hasUnred,
                }
              )}
            />
            <InboxIcon
              className="w-[24px] h-[24px]"
              unreadClass="w-[12px] h-[12px]"
              unread={unread}
            />
          </div>
          <h3 className="m-0 text-l text-grey-600 font-black">
            {t("inbox.notifications")}
          </h3>
        </div>
        <SwitchTab active={activeFolder} setActive={setActiveFolder} />
        <InboxMessageContainer active={activeFolder} />
        <div className="flex h-full items-center justify-center bg-black-200 px-space-3 py-space-2">
          <button
            className={clsx(
              "flex-grow",
              "border-grey-800 rounded-xs text-s text-grey-400 bg-black-600/80 duration-150 hover:text-orange-800 hover:border-orange-800"
            )}
            onClick={handleReadAll}
          >
            {t("button.mark-all-as-read")}
          </button>
        </div>
      </animated.div>
      <InboxModal />
    </>
  );
};

export { Inbox };
