import { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import { Container } from "./container";
import { MobileNavV2 } from "./nav/mobile-nav-v2";
import { NavV3 } from "./nav/nav-v3";
import { FooterV3 } from "src/components/footer-v3";
import { QuickSidebar } from "src/components/quick-sidebar";
import { AnnouncementBar } from "src/components/announcement-bar";
import { Inbox } from "src/components/inbox";
import { InboxProvider } from "src/components/inbox-provider";
import { BottomMenu } from "./bottom-menu";
import { isMobile } from "react-device-detect";
import { useWindowSize } from "@uidotdev/usehooks";
import { Leaderboard } from "./leaderboard/leaderboard";
import { RenderModal } from "./modal";

export const ContainerV3 = (props: any) => {
  const { children, withBg = true, isFixedNav = true } = props;
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openDrawerCounter, setOpenDrawerCounter] = useState<number>(0);

  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  // Only in /mystery-box will show leaderboard
  const showLeaderboard = location.pathname === "/mystery-box";

  return (
    <Container invisibleHeader={true} showOldNav={false}>
      <div
        className={`bg-black min-h-screen w-full p-0`}
        style={{
          overflow: openDrawer ? "hidden" : "auto",
        }}
      >
        <div
          className={`max-w-full min-w-full lg:max-w-[1620px] lg:min-w-[1024px] w-full m-auto relative scrollbar-hidden ${
            isResponsive ? "pb-[75px]" : ""
          }`}
        >
          <NavV3 withBg={false} isFixedNav={true} />

          {showLeaderboard && (
            <div className="flex absolute top-[100px] sm:top-[140px] right-0 z-[45]">
              <Leaderboard />
            </div>
          )}

          <InboxProvider>
            <MobileNavV2
              withBg={withBg}
              setOpenDrawer={setOpenDrawer}
              openDrawer={openDrawer}
              setOpenDrawerCounter={setOpenDrawerCounter}
              openDrawerCounter={openDrawerCounter}
            />
            <Inbox
              className={clsx(
                "fixed right-0 z-[40]",
                "top-[64px] h-[calc(100vh_-_64px)] md:top-[250px] md:h-[calc(100vh_-_250px)]"
              )}
            />
          </InboxProvider>
          <AnnouncementBar
            className={clsx("w-full h-[24px] z-50", {
              "fixed top-[40px] md:top-[72px]": isFixedNav,
            })}
          />

          {/* Content */}
          <div
            className={`relative z-[2] ${
              isFixedNav
                ? "top-[40px] md:top-[100px] pb-[40px] md:pb-[100px]"
                : ""
            }`}
          >
            {children}

            {/* Footer */}
            <div className="relative z-[2]">
              <FooterV3 />
            </div>
          </div>

          <QuickSidebar />
        </div>
      </div>

      {/* Bottom Menu */}
      {isResponsive && <BottomMenu />}

      {/* Modals */}
      <RenderModal />
    </Container>
  );
};
