export enum Token {
  MAX = "MAX",
  STAR = "STAR",
  GMAX = "MAX",
  GSTAR = "STAR",
  GDD = "GDD",
  GP = "GP",
}

export enum Currency {
  MAX = "MAX",
  USDT = "USDT",
}
