import React, { useEffect, useState } from "react";
import { useDashboardStore } from "src/stores";
import { FaCopy } from "react-icons/fa";
import { openSuccessNotification } from "./notification";
import QRCode from "react-qr-code";
import { useWindowSize } from "@uidotdev/usehooks";
import { isMobile } from "react-device-detect";

const assetCodes = ["MAX"];

interface IModalDepositCryptoV2 {
  disclaimerContent: string;
}

export const ModalDepositCryptoV2: React.FC<IModalDepositCryptoV2> = (
  props: IModalDepositCryptoV2
) => {
  const { disclaimerContent } = props;
  const [address, setAddress] = useState<string | null>(null);
  const { getCryptoDepositAddress } = useDashboardStore();

  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  // Get Address
  useEffect(() => {
    const getAddress = async () => {
      const result = await getCryptoDepositAddress("MAX");
      if (!result) return;
      setAddress(result);
    };

    getAddress();
  }, [getCryptoDepositAddress]);

  return (
    <div className="flex flex-col gap-space-4 rounded-s px-space-4 py-space-2 bg-black overflow-hidden overflow-y-auto scrollbar-hide scrollbar-hidden">
      {/* Asset Codes */}
      <div className="flex flex-col gap-space-1">
        <p className="text-xs">Asset</p>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-space-1">
          {assetCodes &&
            assetCodes.map((val, index) => (
              <button
                key={index}
                className={`flex h-[36px] px-[10px] py-space-3 rounded-xs items-center justify-center
            bg-grey-200/10 font-[500] text-xs md:text-s border-[1px] border-solid
            ${
              val !== "MAX"
                ? "border-black-100 text-black-100"
                : "border-orange-800 text-orange-800"
            }`}>
                {val}
              </button>
            ))}
        </div>
      </div>

      <div className="flex flex-col gap-space-0">
        <p className="text-m text-grey-100 font-[700]">
          Deposit via wallet address/QR
        </p>
        <p className="text-xs text-grey-600">
          Deposit can be made to the following address listed below or by
          scanning the QR
        </p>
      </div>

      {/* QR Code */}
      {address && (
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 180,
            width: "100%",
          }}>
          <QRCode
            size={180}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            fgColor="#000"
            bgColor="#fff"
            value={address}
          />
        </div>
      )}

      {address && (
        <div className="relative">
          <input
            type="text"
            value={isResponsive ? `${address.slice(0, 20)}...` : address}
            disabled
            className="py-space-4 w-full h-[40px] text-left placeholder-left pl-space-4 border-black-200 rounded-xs border-solid border-[1px] placeholder-black-200 text-grey-800 placeholder-blender-medium placeholder-font-[700] placeholder-text-l font-[400] text-s blender-medium bg-transparent placeholder-center placeholder-text-grey-600 focus:border-orange-800 focus:outline-none pr-space-10 overflow-hidden"
          />
          <FaCopy
            className="w-[14px] h-[14px] md:w-[18px] md:h-[18px] absolute right-space-2 top-[12px] md:top-[10px] cursor-pointer"
            color="#737373"
            onClick={() => {
              navigator.clipboard.writeText(address);
              openSuccessNotification("You have copied the address");
            }}
          />
        </div>
      )}

      <div className="flex flex-col gap-space-2">
        <p className="text-m text-grey-100 font-[700]">Details</p>
        {/* Notes */}
        <div className="flex flex-row justify-between">
          <p className="text-xs text-grey-600">Minimum Deposit</p>
          <p className="text-xs text-orange-600">25 MAX</p>
        </div>
      </div>

      {/* Disclaimer */}
      <div className="flex flex-col gap-space-1">
        <p className="blender-medium font-[500] text-native-red text-s">
          *Disclaimer*
        </p>
        <p className="blender-medium font-[400] text-s text-grey-600">
          {disclaimerContent}
        </p>
      </div>
    </div>
  );
};
