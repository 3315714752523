import { useEffect, useState, useMemo } from "react";
import { IMysteryBox } from "src/types/mysteryBox";
import {
  ICaseOpeningBoxCardData,
  ICaseOpeningFilters,
} from "src/types/case-opening";
import { useMarketplaceStore, history } from "src/stores";
import { openWarningNotification } from "src/components/notification";
import { CaseOpeningBoxCard } from "./case-opening-box-card";
import { gedaCaseGifMap } from "src/utils/case";
import clsx from "clsx";
import { useIsPcScreenWidth } from "src/utils/rwd";
import { LoadingIconV2 } from "./loading-icon-v2";

interface ICaseOpeningBoxCardList {
  className?: string;
  title: React.ReactNode | string;
  type: string;
  filters?: ICaseOpeningFilters;
}

const CaseOpeningBoxCardList = ({
  className,
  title,
  type,
  filters,
}: ICaseOpeningBoxCardList) => {
  const [boxList, setBoxList] = useState<ICaseOpeningBoxCardData[]>([]);
  const [loading, setLoading] = useState(false);
  const { getMysteryBoxList } = useMarketplaceStore();
  const isPC = useIsPcScreenWidth();

  const goToPage = ({ _id, isSelectable }: ICaseOpeningBoxCardData) => {
    if (!isSelectable)
      return openWarningNotification("Please stay tuned for the upcoming box.");

    history.push(`/mystery-box/${_id}`);
  };

  useEffect(() => {
    setLoading(true);
    getMysteryBoxList().then((result: any[]) => {
      setLoading(false);
      if (!result) return;
      setBoxList(
        result.map(
          (
            {
              _id,
              name,
              price,
              img_url,
              gif_url,
              img_file,
              is_new,
              selectable,
              currency,
              final_price,
            }: IMysteryBox,
            i
          ): ICaseOpeningBoxCardData => {
            const imgUrls = [
              img_url || img_file || "https://media.geda.gg/default_case.png",
            ];
            if (isPC) {
              if (gif_url) imgUrls.push(gif_url);
              if (!gif_url) imgUrls.push(gedaCaseGifMap[name]);
            }

            return {
              _id: _id || String(i),
              name,
              price,
              imgUrls,
              isNew: is_new,
              isSelectable: selectable,
              currency: currency || "USDT",
              finalPrice: final_price || price,
            };
          }
        )
      );
    });
  }, [getMysteryBoxList, isPC]);

  const filteredList = useMemo(() => {
    if (!filters) return boxList;

    const { name, range = [], sort } = filters;
    const trimedName = name?.trim();
    let [max, min] = range;
    let filteredBoxList: ICaseOpeningBoxCardData[] = [...boxList];

    // filter name
    if (trimedName) {
      filteredBoxList = filteredBoxList.filter((box) =>
        box.name.toLowerCase().includes(trimedName)
      );
    }

    // filter range
    if (min || max) {
      const _max: number = typeof max === "number" ? max : Infinity;
      const _min: number = typeof min === "number" ? min : -Infinity;

      filteredBoxList = filteredBoxList.filter(
        ({ price }) => price && price <= _max && price >= _min
      );
    }

    // filter platform
    filteredBoxList = filteredBoxList.filter((box) => {
      return box.currency === type.toUpperCase();
    });

    // filter sort
    if (sort) {
      const isOrderByDesc = sort === "desc";
      const isOrderByAsc = sort === "asc";

      if (isOrderByDesc) {
        filteredBoxList.sort((a, b) => (b.price ?? 0) - (a.price ?? 0));
      }

      if (isOrderByAsc) {
        filteredBoxList.sort((a, b) => (a.price ?? 0) - (b.price ?? 0));
      }
    }

    return filteredBoxList;
  }, [boxList, filters, type]);

  return (
    <div>
      {title}

      <div
        className={clsx(
          className,
          "grid grid-cols-2 sm:grid-cols-5 md:grid-cols-5 l:grid-cols-6 gap-space-2 md:gap-space-4 items-between"
        )}
      >
        {loading && (
          <div className="flex justify-center items-center py-space-8">
            <LoadingIconV2 />
          </div>
        )}
        {!filteredList ||
          (filteredList.length === 0 && (
            <div className="flex justify-center items-center py-space-8">
              <p className="text-m blender-medium text-grey-600">
                New case will be available soon.
              </p>
            </div>
          ))}
        {!loading &&
          filteredList.map((box) => {
            const {
              _id,
              name,
              price,
              imgUrls,
              isNew,
              isSelectable,
              finalPrice,
              currency,
            } = box;

            return (
              <CaseOpeningBoxCard
                key={_id}
                name={name}
                price={price}
                imgUrls={imgUrls}
                isNew={isNew}
                isSelectable={isSelectable}
                onClick={() => goToPage(box)}
                currency={currency}
                finalPrice={finalPrice}
              />
            );
          })}
      </div>
    </div>
  );
};

export { CaseOpeningBoxCardList };
