import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";
import {
  ICreateDepositAddress,
  ICreateDepositOrder,
  ISendP2PTransfer,
  IExchangeCryptoToGS,
} from "src/types/dashboard";

export class DashboardStore {
  async createDepositOrder(payload: ICreateDepositOrder) {
    const url = `${Configs.API_URL}/v1/dashboard/deposit/create`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async createDepositAddress(payload: ICreateDepositAddress) {
    const url = `${Configs.API_URL}/v1/dashboard/deposit/crypto/address`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getCryptoDepositAddress(currency: string) {
    const url = `${Configs.API_URL}/v2/dashboard/deposit/${currency}/address`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async sendP2PTransfer(payload: ISendP2PTransfer) {
    const url = `${Configs.API_URL}/v1/dashboard/transfer/p2p`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }
  async exchangeCryptoToGS(payload: IExchangeCryptoToGS) {
    const url = `${Configs.API_URL}/v2/dashboard/transfer/convert-to-gs`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result.data.success;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_DASHBOARD = "dashboardStore";
