import { useEffect, useRef } from "react";
import clsx from "clsx";
import { IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import {
  formatName,
  formatPrice,
  getRarityColor,
  getPropertyColor,
} from "src/utils";
import { calculateWeaponPrice } from "src/utils/case";
import { getCurrencyIcon } from "src/utils/image";
import { BsBoxArrowUp } from "react-icons/bs";

interface ICaseOpeningSelectOption {
  className?: string;
  item: IMysteryBoxPrizeDetail;
  isValuableWeapon: boolean;
  onSell: () => void;
}

const CaseOpeningSelectOption = ({
  className,
  item,
  isValuableWeapon,
  onSell,
}: ICaseOpeningSelectOption) => {
  const {
    name = "",
    quality = "",
    property = "",
    imgUrl = "",
    sellCurrency,
    type = "",
  } = item || {};
  const rarityColor = getRarityColor(quality);
  const propertyColor = getPropertyColor(property);
  const calculatedPrice = formatPrice(calculateWeaponPrice(item));

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video && isValuableWeapon) {
      video.play();
      video.autoplay = true;
      video.loop = true;
    }

    return () => {
      if (video) {
        video.pause();
      }
    };
  }, [videoRef, isValuableWeapon]);

  return (
    <div
      className={clsx(
        className,
        "case-opening-select-option-border-color",
        "relative md:flex md:p-space-2 md:gap-space-4 md:flex-row md:items-center",
        "grid grid-cols-3 p-space-1 w-[240px] md:w-[576px] md:h-[120px] overflow-clip"
      )}
      style={{
        borderTopColor: rarityColor,
      }}
    >
      {isValuableWeapon && (
        <video
          ref={videoRef}
          className="absolute left-0 top-0 w-[240px] h-[240px] md:w-[576px] md:h-[120px]"
          muted
        >
          <source
            src="/assets/v4/legendary-frame.mp4"
            type="video/mp4"
            media="(min-width: 1080px)"
          />
          <source
            src="/assets/v4/legendary-square-frame.mp4"
            type="video/mp4"
          />
        </video>
      )}
      {/* Item image */}
      <div
        className={clsx(
          "flex items-center justify-center",
          "relative md:shrink-0 md:basis-[100px]",
          "row-span-2"
        )}
      >
        <img className="md:h-full" src={imgUrl} alt="" />
      </div>

      {/* Description */}
      <div
        className={clsx(
          "blender-medium text-xs md:text-s",
          "relative flex flex-col gap-space-1 overflow-hidden",
          "md:flex-1 md:w-full",
          "col-span-2"
        )}
      >
        <div
          className={clsx(
            "col-span-full md:justify-self-start",
            "text-grey-100 leading-none text-left text-s md:text-l"
          )}
        >
          {formatName(name)}
        </div>
        <div className="col-span-full text-grey-800 leading-none flex items-center justify-between">
          <div>Weapon Class: </div>
          <div className="text-grey-200">{formatName(type)}</div>
        </div>
        <div className="col-span-full text-grey-800 leading-none flex items-center justify-between">
          <div>Rarity:</div>
          <div className="text-grey-200 flex items-center">
            <div
              className="rounded-full w-[8px] h-[8px] mr-1"
              style={{ backgroundColor: rarityColor }}
            />
            <p className="leading-none text-xs">{formatName(quality)}</p>
          </div>
        </div>
        <div className="col-span-full text-grey-800 leading-none flex items-center justify-between">
          <div>Wear rating: </div>
          <div className="uppercase" style={{ color: propertyColor }}>
            {property}
          </div>
        </div>
      </div>
      {/* Dividing */}
      <div className="hidden relative md:block basis-[1px] h-[100px] shrink-0 case-opening-dividing-line" />
      {/* Sell Button */}
      <div
        className={clsx(
          "blender-medium md:flex-1 flex flex-col items-start justify-center",
          "relative col-span-2"
        )}
      >
        <div
          className={clsx(
            "hidden md:block",
            "col-span-full md:justify-self-start text-s md:text-l text-grey-100 leading-none text-right md:text-left"
          )}
        >
          Item options
        </div>
        <div className="w-full mt-1 md:mt-2 flex justify-between items-center">
          <div className="leading-none">
            <div className="text-s md:text-m text-grey-100">Market Value</div>
            <div>
              <div className="flex items-center justify-start text-s md:text-l ">
                <div className="text-native-green leading-none">+</div>
                <img
                  src={getCurrencyIcon(sellCurrency || "USDT")}
                  className="w-[14px] h-[14px] mr-space-1"
                  alt=""
                />
                <div className="text-native-green leading-none">
                  {calculatedPrice}
                </div>
              </div>
            </div>
          </div>
          <button
            className={clsx(
              "flex items-center justify-center gap-2 p-1 md:p-2",
              "border border-[#737373] rounded-xs text-ms cursor-pointer normal-case",
              "leading-none"
            )}
            onClick={onSell}
          >
            <BsBoxArrowUp className="text-[8px] md:text-[12px]" />
            <div className="text-s md:text-l">Sell</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export { CaseOpeningSelectOption };
