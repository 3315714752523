import { useState, useEffect, useRef, useCallback } from "react";
import clsx from "clsx";
import { CgSignal } from "react-icons/cg";
import { Marquee } from "src/components/marquee";
import { useWebSocket } from "src/components/websocket-context";
import {
  getRarityColor,
  formatPrice,
  formatTextToUpperCase,
  convertTokenName,
} from "src/utils";
import { IAnnouncedWeapon } from "src/types/case-opening";
import { useMarketplaceStore } from "src/stores";
import { t } from "i18next";

const Connection = ({ isConnected }: { isConnected: boolean }) => {
  return (
    <div
      className={clsx("flex items-center justify-center border-r-[2px]", {
        "text-[#1EBB71] border-r-[#1EBB71]": isConnected,
        "text-[#D40C48] border-r-[#D40C48]": !isConnected,
      })}
    >
      <CgSignal className="w-[16px] h-[16px]" />
    </div>
  );
};
interface WeaponBulletinProps extends IAnnouncedWeapon {
  index: number;
  bulletinIdx: number;
  onOldestElementVisible: () => void;
}

const WeaponBulletin = ({
  username,
  itemName,
  itemQuality,
  weaponPrice,
  currency = "GSTAR",
  boxPrice,
  boxName,
  index,
  bulletinIdx,
  onOldestElementVisible,
}: WeaponBulletinProps) => {
  const bulletin = useRef(null);

  useEffect(() => {
    const currentBulletin = bulletin.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && index === bulletinIdx) {
            onOldestElementVisible();
          }
        });
      },
      {
        root: null,
        threshold: [0],
      }
    );

    if (currentBulletin) {
      observer.observe(currentBulletin);
    }

    return () => {
      if (currentBulletin) {
        observer.unobserve(currentBulletin);
      }
    };
  }, [index, bulletinIdx, onOldestElementVisible]);

  return (
    <p ref={bulletin} className="blender-medium min-w-[600px] md:min-w-[750px]">
      <span className="mx-space-4">✹</span>
      <span className="text-grey-100 mx-space-1">
        {formatTextToUpperCase(username)}
      </span>
      <span className="text-grey-800 mx-space-1">{t("announcement.won")}</span>
      <span
        className="mx-space-1"
        style={{ color: getRarityColor(itemQuality) }}
      >
        {`${formatPrice(weaponPrice)} ${convertTokenName(
          currency
        )} ${formatTextToUpperCase(itemQuality)} ${formatTextToUpperCase(
          itemName
        )}`}
      </span>
      <span className="text-grey-100 mx-space-1">
        {t("announcement.with")} {boxPrice}
      </span>
      <span className="text-grey-800 mx-space-1">
        {t("announcement.on")} {formatTextToUpperCase(boxName)}{" "}
        {t("announcement.draw")}
      </span>
    </p>
  );
};

const AnnouncementBar = ({ className }: { className?: string }) => {
  const { announcedWeapon, isConnected } = useWebSocket();
  const { getMysteryBoxRecentWinnings } = useMarketplaceStore();
  const [bulletins, setBulletins] = useState<IAnnouncedWeapon[] | []>([]);
  const [queueingBulletins, setQueueingBulletins] = useState<
    IAnnouncedWeapon[] | []
  >([]);
  const [bulletinIdx, setBulletinIdx] = useState(0);
  const hasValue = Boolean(bulletins.length);
  const maxBulletins = 5;

  useEffect(() => {
    const fetchRecentWinnings = async () => {
      const result = await getMysteryBoxRecentWinnings();
      if (!result || typeof result === "string") return;
      const recentBulletins = result.map((item: any) => {
        const {
          userName,
          itemQuality,
          itemName,
          weaponPrice,
          currency,
          boxPrice,
          boxName,
        } = item;
        return {
          username: userName,
          itemQuality,
          itemName,
          weaponPrice,
          currency,
          boxPrice,
          boxName,
        };
      });
      setBulletins(recentBulletins);
    };

    fetchRecentWinnings();
  }, [getMysteryBoxRecentWinnings]);

  useEffect(() => {
    if (announcedWeapon) {
      setQueueingBulletins((prevBulletins) => {
        // If the announced weapon is legendary, prepend it to the list
        return announcedWeapon.itemQuality === "legendary"
          ? [announcedWeapon, ...prevBulletins]
          : [...prevBulletins, announcedWeapon];
      });
    }
  }, [announcedWeapon]);

  const onOldestElementVisible = useCallback(() => {
    if (queueingBulletins.length) {
      const newBulletins = queueingBulletins.shift();
      setBulletins((prevBulletins) => {
        if (newBulletins) {
          // Replace the oldest bulletin with the new one
          prevBulletins[bulletinIdx % maxBulletins] = newBulletins;
          setBulletinIdx((bulletinIdx + 1) % maxBulletins);
          return prevBulletins;
        }
        return prevBulletins;
      });
    }
  }, [queueingBulletins, bulletinIdx]);

  return (
    <div
      className={clsx(
        className,
        "grid grid-flow-col grid-cols-[32px_1fr]",
        "bg-black-800 md:backdrop-blur-sm shadow-box-4"
      )}
    >
      <Connection isConnected={isConnected} />
      <Marquee
        className={clsx("transition-opacity duration-1000", {
          invisible: !hasValue,
        })}
        repeat={2}
        duration="100s"
        fromColor="from-black-800"
      >
        {hasValue &&
          bulletins.map((bulletin, i) => (
            <WeaponBulletin
              key={bulletin.username + bulletin.itemName + i}
              {...bulletin}
              index={i}
              bulletinIdx={bulletinIdx}
              onOldestElementVisible={onOldestElementVisible}
            />
          ))}
      </Marquee>
      <img
        className="absolute right-0 w-[60px] h-[36px] sm:w-[100px] self-center object-contain"
        src="/assets/v4/update-label.png"
        alt=""
      />
    </div>
  );
};

export { AnnouncementBar };
