import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGE_LIST } from "src/config/language-list";

export const useLanguage = () => {
  const { i18n } = useTranslation();

  const [langCode, setLangCode] = useState(
    localStorage.getItem("geda-language") || "en"
  );
  const [openLanguageList, setOpenLanguageList] = useState(false);
  const [languageLabel, setLanguageLabel] = useState<string>("English");

  const handleChangeLanguage = (code: string) => {
    setLangCode(code);
    setOpenLanguageList(false);
    i18n.changeLanguage(code);
  };

  useEffect(() => {
    const label = LANGUAGE_LIST.find((l) => l.code === langCode);
    localStorage.setItem("geda-language", label?.code || "en");
    setLanguageLabel(label?.nativeName || "English");
  }, [langCode]);

  return {
    langCode,
    setLangCode,
    openLanguageList,
    setOpenLanguageList,
    handleChangeLanguage,
    languageLabel,
  };
};
