import { LeaderboardHeader } from "./leaderboard-header";
import { LeaderboardRow } from "./leaderboard-row";
import { ILeaderboardData } from "src/types/leaderboard";
import { LoadingIconV2 } from "../loading-icon-v2";

interface ILeaderboardBody {
  displayBoard: boolean;
  setDisplayBoard: React.Dispatch<React.SetStateAction<boolean>>;
  rankings: ILeaderboardData[];
  myRanking: ILeaderboardData | null;
  loading: boolean;
  loggedIn: boolean;
}

export const LeaderboardBody = (props: ILeaderboardBody) => {
  const { rankings, myRanking, loading, loggedIn } = props;
  return (
    <div
      className="bg-black-400 w-[360px]"
      style={{ borderRadius: "0px 0px 8px 8px" }}>
      <div
        className="bg-black-400 p-space-2"
        style={{ borderRadius: "0px 0px 8px 8px" }}>
        <LeaderboardHeader />
        {!loading && rankings && rankings.length > 0 && (
          <>
            {/* fixed user ranking */}
            {loggedIn && myRanking && (
              <div className="absolute top-[95px] left-0 right-0 z-[1] mx-space-2">
                <LeaderboardRow item={myRanking} isUser={true} />;
              </div>
            )}
            {/* top 100 rankings */}
            <div
              className={`overflow-y-auto max-h-[312px] ${
                loggedIn ? `mt-space-9 sm:mt-space-10` : ""
              }`}>
              {rankings.map((item: ILeaderboardData, index: number) => {
                return (
                  <LeaderboardRow item={item} key={index} isUser={false} />
                );
              })}
            </div>
          </>
        )}

        {loading && (
          <div className="flex justify-center items-center mb-space-2">
            <LoadingIconV2 />
          </div>
        )}
      </div>
    </div>
  );
};

export const LeaderboardMobileBody = (props: ILeaderboardBody) => {
  const { rankings, myRanking, setDisplayBoard, loading, loggedIn } = props;
  return (
    <div
      className="bg-black-400 w-[324px] ml-space-2"
      style={{ borderRadius: "0px 0px 8px 8px" }}>
      <div
        className="bg-black-400 p-0 sm:px-space-2"
        style={{ borderRadius: "0px 0px 8px 8px" }}>
        <LeaderboardHeader />
        {!loading && rankings && rankings.length > 0 && (
          <>
            {/* fixed user ranking */}
            {loggedIn && myRanking && (
              <div className="absolute top-[80px] left-2 right-0 z-[1] mx-0 ">
                <LeaderboardRow item={myRanking} isUser={true} />;
              </div>
            )}
            {/* top 100 rankings */}
            <div
              className={`overflow-y-auto max-h-[220px] ${
                loggedIn ? `mt-space-9 sm:mt-space-10` : ""
              }`}>
              {rankings.map((item: ILeaderboardData, index: number) => {
                return (
                  <LeaderboardRow item={item} key={index} isUser={false} />
                );
              })}
            </div>
          </>
        )}
        {loading && (
          <div className="flex justify-center items-center mb-space-2">
            <LoadingIconV2 />
          </div>
        )}
        <div
          className="flex justify-center mt-space-2 cursor-pointer"
          onClick={() => setDisplayBoard(false)}>
          <img
            src="/assets/v4/leaderboard-close.png"
            alt="ranking"
            className="w-[24px] h-[24px]"
          />
        </div>
      </div>
    </div>
  );
};
