import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMarketplaceStore } from "src/stores";
import { IMysteryBox, IMysteryBoxItem } from "src/types/mysteryBox";
import { CaseOpeningMysteryBoxItemList } from "src/components/case-opening-mystery-box-item-list";

export const CaseOpeningPotentialPrizes = ({
  boxData,
}: {
  boxData: IMysteryBox | null;
}) => {
  const { id } = useParams<{ id: string }>();
  const { getMysteryBoxItems } = useMarketplaceStore();
  const [boxItems, setBoxItems] = useState<IMysteryBoxItem[] | null>(null);

  useEffect(() => {
    if (!id) return;

    getMysteryBoxItems(id).then((result?: IMysteryBoxItem[]) => {
      if (!result) return;

      setBoxItems(result);
    });
  }, [id, getMysteryBoxItems]);

  return (
    <CaseOpeningMysteryBoxItemList
      title="Potential prizes"
      items={boxItems}
      layoutClassName="grid-flow-row grid-cols-[repeat(2,_minmax(148px,_1fr))] sm:grid-cols-[repeat(5,_minmax(148px,_1fr))] md:grid-cols-[repeat(5,_minmax(192px,_1fr))] l:grid-cols-[repeat(7,_minmax(192px,_1fr))] overflow-x-auto scrollbar-hidden"
      currency={boxData?.currency}
    />
  );
};
