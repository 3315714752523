import { useEffect, useState, useContext, useRef, useMemo } from "react";
import { useParams } from "react-router";
import clsx from "clsx";
import { useMarketplaceStore } from "src/stores";
import {
  IMysteryBox,
  IMysteryBoxItem,
  IMysteryBoxPrizeDetail,
} from "src/types/mysteryBox";
import {
  openWarningNotification,
  openSuccessNotification,
} from "src/components/notification";
import { CaseOpeningBoxSelectedModal } from "src/components/case-opening-box-selected-modal";
import { CaseOpeningBoxSingleResultModal } from "src/components/case-opening-box-single-result-modal";
import { CaseOpeningBoxMultiResultModal } from "./case-opening-box-multi-result-modal";
import { CaseOpeningRouletteContext } from "src/components/case-opening-roulette-provider";
import { CaseOpeningRouletteLayout } from "src/components/case-opening-roulette-layout";
import { VolumeBar } from "./volume-bar";
import { useAuth } from "./auth-context";
import { history } from "src/stores";
import { isValuablePrize } from "src/utils/case";

interface RouteParams {
  id: string;
}

export const CaseOpeningMainArea = ({
  boxData,
}: {
  boxData: IMysteryBox | null;
}) => {
  const { id } = useParams<RouteParams>();
  const { storeBoxItems, spin, isRolling, isHighlightPhase } = useContext(
    CaseOpeningRouletteContext
  );
  const [selectedNum, setSelectedNum] = useState<number>(1);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [prizes, setPrizes] = useState<IMysteryBoxPrizeDetail[]>([]);
  const [originalPrizes, setOriginalPrizes] = useState<
    IMysteryBoxPrizeDetail[]
  >([]);
  const [gdd, setGdd] = useState<number>(0);
  const [isBoxModalVisible, setIsBoxModalVisible] = useState<boolean>(true);
  const [isResultModalVisible, setIsResultModalVisible] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSelling, setIsSelling] = useState<boolean>(false);

  const { loggedIn, setOpenLoginModal, getDashboardProfileCb } = useAuth();
  const {
    getMysteryBoxItems,
    openMysteryBoxV3,
    sellItemsById: sellWeapons,
  } = useMarketplaceStore();

  const audioRef = useRef<HTMLVideoElement | null>(null);
  const isValuable = useMemo<boolean[]>(() => {
    if (!boxData || !prizes) {
      return [];
    }

    return prizes.map((item) => isValuablePrize(item, boxData));
  }, [boxData, prizes]);
  const isOriginPrizesValuable = useMemo<boolean[]>(() => {
    if (!boxData || !originalPrizes) {
      return [];
    }

    return originalPrizes.map((item) => isValuablePrize(item, boxData));
  }, [boxData, originalPrizes]);

  // Initialize
  useEffect(() => {
    setSelectedNum(1);
    setShowAnimation(false);
    setPrizes([]);
    setOriginalPrizes([]);
    setIsBoxModalVisible(true);
    setIsResultModalVisible(false);
    setIsLoading(false);

    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, [id]);

  useEffect(() => {
    if (!id) return;

    getMysteryBoxItems(id).then((result?: IMysteryBoxItem[]) => {
      if (!result) return;

      storeBoxItems(result);
    });
  }, [id, getMysteryBoxItems, storeBoxItems]);

  useEffect(() => {
    if (isHighlightPhase && originalPrizes.length) {
      setTimeout(() => {
        setIsResultModalVisible(true);
      }, 1300);
    }
  }, [isHighlightPhase, originalPrizes]);

  useEffect(() => {
    const audio = audioRef.current;
    const hasValuablePrize = isOriginPrizesValuable.includes(true);

    if (audio) {
      if (hasValuablePrize && isResultModalVisible) {
        audio.muted = false;
        audio.currentTime = 0;
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [isResultModalVisible, isOriginPrizesValuable]);

  const handleSpin = async () => {
    if (isLoading) return;
    if (!loggedIn) {
      setOpenLoginModal(true);
      return openWarningNotification("Please login to open the case.");
    }

    setIsLoading(true);
    const result = await openMysteryBoxV3({
      id: id,
      quantity: selectedNum,
    });
    setIsLoading(false);
    if (!result) return openWarningNotification("Failed to open mystery box.");

    setIsBoxModalVisible(false);
    setIsResultModalVisible(false);
    setPrizes(() => {
      if (result?.prizes && boxData) {
        return [...result?.prizes].sort((item: IMysteryBoxPrizeDetail) =>
          isValuablePrize(item, boxData) ? -1 : 0
        );
      }

      return [];
    });
    setOriginalPrizes(result?.prizes || []);
    setGdd(result?.gdd || 0);
    setShowAnimation(true);
    getDashboardProfileCb();
    spin(isRolling);
  };

  const handleCloseResultModal = () => {
    setPrizes([]);
    setOriginalPrizes([]);
    setShowAnimation(false);
    setIsBoxModalVisible(true);
    setIsResultModalVisible(false);
  };

  const handleRetry = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    handleSpin();
  };

  const handleSellItem = async (type = "sell-selected", index?: number) => {
    let sellPrizeIds: string[] = [];
    let restPrizes: IMysteryBoxPrizeDetail[] = [];
    if (isSelling) return;

    if (type === "sell-all") {
      if (!prizes.length) return;
      sellPrizeIds = prizes.map(({ _id }: IMysteryBoxPrizeDetail) => _id);
      restPrizes = [];
    }

    if (type === "sell-selected") {
      if (index === undefined) return;
      sellPrizeIds.push(prizes[index]._id);
      restPrizes = prizes.filter((_, i) => i !== index);
    }

    if (sellPrizeIds.length === 0)
      return openWarningNotification("No items to sell.");

    setIsSelling(true);
    const result = await sellWeapons({ ids: sellPrizeIds });
    setIsSelling(false);
    if (!result) return openWarningNotification("Failed to sell item.");

    setPrizes(restPrizes);
    openSuccessNotification(
      sellPrizeIds.length > 1 ? "Items are sold!" : "Item is sold!"
    );
    getDashboardProfileCb();
    if (!restPrizes.length) handleCloseResultModal();
  };

  return (
    <div className="relative flex h-[calc(100svh_-_80px)] min-h-[520px] md:h-[622px]">
      <div className="absolute w-full h-full top-0 left-0 overflow-hidden">
        <img
          className="w-full h-full object-cover md:-translate-y-[25%] md:h-auto"
          src="/assets/v4/overlay-faded.png"
          alt="background"
        />
      </div>
      <div className="absolute bottom-[50%] left-space-5 z-20 cursor-pointer">
        <VolumeBar />
      </div>

      {/* Box Selected Modal */}
      <CaseOpeningBoxSelectedModal
        box={boxData}
        selectedNum={selectedNum}
        isVisible={isBoxModalVisible}
        onClose={() => history.push("/mystery-box")}
        onClickNum={(num: number) => setSelectedNum(num)}
        onClickOpen={() => handleSpin()}
      />
      {/* Result Modal */}
      {boxData &&
        (prizes.length > 1 ? (
          <CaseOpeningBoxMultiResultModal
            prizes={prizes}
            isVisible={isResultModalVisible}
            isValuable={isValuable}
            isSelling={isSelling}
            bonus={gdd}
            onClose={handleCloseResultModal}
            onRetry={handleRetry}
            onSell={handleSellItem}
          />
        ) : (
          <CaseOpeningBoxSingleResultModal
            prizes={prizes}
            isVisible={isResultModalVisible}
            isValuable={isValuable}
            isSelling={isSelling}
            bonus={gdd}
            onClose={handleCloseResultModal}
            onRetry={handleRetry}
            onSell={handleSellItem}
          />
        ))}
      {/* Roulette Area */}
      {showAnimation && (
        <CaseOpeningRouletteLayout
          totalRoulette={selectedNum}
          prizes={originalPrizes}
          boxData={boxData}
        />
      )}
      {/* Gradient Overlay */}
      <div className="absolute w-full h-full top-0 left-0 bg-[radial-gradient(rgba(0,0,0,0),rgba(0,0,0,0.8))]" />
      <div
        className={clsx(
          "z-10 absolute w-full h-full top-0 left-0 flex items-center justify-center",
          "bg-black transition-opacity",
          {
            "invisible opacity-0 pointer-events-none": !(
              isLoading || isSelling
            ),
          },
          { "visible opacity-60": isLoading || isSelling }
        )}
        onClick={(e) => {
          if (isLoading || isSelling) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        <img
          className="w-[80px] h-[80px] md:w-[200px] md:h-[200px]"
          src={"/assets/v4/loading.gif"}
          alt="loading"
        />
      </div>
      <audio ref={audioRef} src="/assets/v4/electric-sound.mp3" muted />
    </div>
  );
};
